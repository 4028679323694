$('.menuItemContainer')
    .on('mouseenter', function() {
        const selectedElement = $(this);
        setTimeout(function() {
            if ($(selectedElement).is(':hover')) {
                const megaNav = selectedElement.children('.menuLevelContent').first();
                megaNav.removeClass('hidden');

                const button = selectedElement.children('.menuEntry').first();
                button.addClass('hover').attr('aria-expanded', 'true');

                const buttonIcon = button.find('i');
                buttonIcon.removeClass('fa-angle-down').addClass('fa-angle-up');
            }
        }, 300);
    })
    .on('mouseleave', function() {
        const selectedElement = $(this);
        setTimeout(function() {
            if ($(selectedElement).is(':hover') === false) {
                const megaNav = selectedElement.children('.menuLevelContent').first();
                megaNav.addClass('hidden');

                const button = selectedElement.children('.menuEntry').first();
                button.removeClass('hover').attr('aria-expanded', 'false');

                const buttonIcon = button.find('i');
                buttonIcon.removeClass('fa-angle-up').addClass('fa-angle-down');
            }
        }, 300);
    });

$('.menuEntry').on('click', function() {
    const selectedElement = $(this);
    if (selectedElement.hasClass('hover')) {
        selectedElement.removeClass('hover').attr('aria-expanded', 'false');
        selectedElement.siblings('.menuLevelContent').addClass('hidden');

        const buttonIcon = $(this).find('i');
        buttonIcon.removeClass('fa-angle-up').addClass('fa-angle-down');
    } else {
        selectedElement.addClass('hover').attr('aria-expanded', 'true');
        selectedElement.siblings('.menuLevelContent').removeClass('hidden');

        const buttonIcon = $(this).find('i');
        buttonIcon.removeClass('fa-angle-down').addClass('fa-angle-up');
    }
});
