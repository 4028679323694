import * as Cookies from "js-cookie";
//Blendet eine Allgemeine Fehlermeldung ein mit dem übergebenen Text, die nach 6 Sekunden langsam ausblendet
export function dangerAlert(text) {
    if (text) {
        $("#dangerAlert").removeClass("hide").fadeIn("fast").find("p").html(text);
    }
}

export function successAlert(text) {    
    if (text) {
        $("#successAlert").removeClass("hide").fadeIn("fast").find("p").html(text);
    }
}

export function closeAlert(elementId, doNotShowAgain) {
    if (doNotShowAgain) {
        Cookies.set("doNotShowAgain" + elementId, "True");
    }
    $(elementId).fadeOut("slow", () => {
        $(elementId).addClass("hide").find("p").html("");
    });
}