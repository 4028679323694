import ILineItem = CheckoutflowReponse.ILineItem;
import IPrice = CheckoutflowReponse.IPrice;
import { IPriceCalculation } from '../models/pricecalculation';
export var summeProbeAbosWerte: number = 0;
export var summeProbeAbosGrossWerte: number = 0;

export function getProductGrossPrice(lineItem: ILineItem): number {
    /*Anpassung für Probe-Abos (Gross)
    
    Hier die Formel für den errechneten Wert eines Probe-Abos (BYTE-261):

    Höchster verfügbarer Produktpreis * Wandlungsrate (0.2) * Faktor für angenommene Laufzeit

    > Höchster, verfügbarer Produktpreis > max(PriceGross)
    > Wandlungsrate > aus der Datenanalyse gehen wir von einer Wandlungsrate von 20% aus
    > Faktor für die angenommene Laufzeit (2 Jahre) > richtet sich nach den Abrechnungszeiträumen der Abos. Hier die Regeln dazu:

    Faktor 4 (4 Halbjahre sind 2 Jahre) > Wenn das Feld "Preisbeschreibung" "halbjährlich" ODER "Halbjahr"enthält
    Faktor 2 (2 ganze Jahre sind 2 Jahre) > Wenn das Feld nichts enthält, "^jährlich.", "\sjährlich." oder "^Jahres.*" enthält*/
    if (lineItem.currentPriceType.priceTypeId.toString()[0] === '4' && lineItem.singleItemGrossPrice.amount == 0) {
        const wandlungsrate = 0.2;
        let hoechsterPreis = getHighestValidPriceFromLineItem(lineItem.product.prices);
        let laufzeitFaktor = getLaufzeitFaktor(hoechsterPreis.priceGross, hoechsterPreis.description);
        if (hoechsterPreis && laufzeitFaktor) {
            let aboWert = hoechsterPreis.priceGross * wandlungsrate * laufzeitFaktor;
            summeProbeAbosGrossWerte += aboWert;

            return aboWert;
        }
    }

    return lineItem.singleItemGrossPrice ? lineItem.singleItemGrossPrice.amount : null;
}

/**
 * Im priceCalculation müssen die Preise valid übergeben werden.
 * @param priceCalculation
 * @param lineItem
 */
export function getProductNetPrice(lineItem: ILineItem, priceCalculation?: IPriceCalculation): number {
    /*Anpassung für Probe-Abos (Net)
    
    Hier die Formel für den errechneten Wert eines Probe-Abos (BYTE-261):

    Höchster verfügbarer Produktpreis * Wandlungsrate (0.2) * Faktor für angenommene Laufzeit

    > Höchster, verfügbarer Produktpreis > max(PriceGross)
    > Wandlungsrate > aus der Datenanalyse gehen wir von einer Wandlungsrate von 20% aus
    > Faktor für die angenommene Laufzeit (2 Jahre) > richtet sich nach den Abrechnungszeiträumen der Abos. Hier die Regeln dazu:

    Faktor 4 (4 Halbjahre sind 2 Jahre) > Wenn das Feld "Preisbeschreibung" "halbjährlich" ODER "Halbjahr"enthält
    Faktor 2 (2 ganze Jahre sind 2 Jahre) > Wenn das Feld nichts enthält, "^jährlich.", "\sjährlich." oder "^Jahres.*" enthält*/
    try {
        if (!lineItem && !priceCalculation) {
            return null;
        }

        if (lineItem) {
            return CalculatePriceFromLineItem(lineItem);
        }
        return CalculatePriceFromPriceCalculationModel(priceCalculation);
    } catch (error) {
        console.error(error);
        return null;
    }
}

export function buildVar5(trackingProduct: CheckoutflowReponse.IProduct): string {
    let firstPart = 'none';
    if (trackingProduct.isTopArticle === true) {
        firstPart = 'isTop';
    } else if (trackingProduct.isStandard === true) {
        firstPart = 'isStandardwerk';
    }

    let secondPart = 'none';
    if (trackingProduct.isNew == true) {
        secondPart = 'isNew';
    } else if (trackingProduct.isPreview == true) {
        secondPart = 'isPreview';
    }

    return `${firstPart}_${secondPart}`;
}

function CalculatePriceFromPriceCalculationModel(priceCalculation: IPriceCalculation) {
    return CalculatePrice(
        priceCalculation.currentPrice,
        priceCalculation.currentPricetypeId,
        priceCalculation.highestPriceDescription,
        priceCalculation.highestPrice
    );
}

function CalculatePriceFromLineItem(lineItem: ILineItem): number {
    let currentPrice: number = lineItem.singleItemNetPrice.amount;
    let currentPriceTypeId: number = lineItem.currentPriceType.priceTypeId;
    let highestPrice: IPrice = getHighestValidPriceFromLineItem(lineItem.product.prices);
    if (!highestPrice) {
        return null;
    }
    let highestPriceDescription: string = highestPrice.description;
    let highestPriceAmount: number = highestPrice.priceNet;
    return CalculatePrice(currentPrice, currentPriceTypeId, highestPriceDescription, highestPriceAmount);
}

function CalculatePrice(
    currentPrice: number,
    currentPriceTypeId: number,
    highestPriceDescription: string,
    highestPrice: number
): number {
    if (currentPriceTypeId.toString()[0] === '4' && currentPrice == 0) {
        const wandlungsrate = 0.2;
        let laufzeitFaktor = getLaufzeitFaktor(highestPrice, highestPriceDescription);
        if (highestPrice && laufzeitFaktor) {
            let aboWert = highestPrice * wandlungsrate * laufzeitFaktor;
            summeProbeAbosWerte += aboWert;

            return aboWert;
        }
    }

    return currentPrice;
}

function getHighestValidPriceFromLineItem(prices: IPrice[]): IPrice {
    let currentDate = new Date();
    let validPrices = prices.filter(
        x =>
            (x.validFrom == null || new Date(x.validFrom) < currentDate) &&
            (x.validUntil == null || new Date(x.validUntil) > currentDate)
    );
    if (!validPrices) {
        return null;
    }
    return validPrices.filter(x => x.priceGross == Math.max(...validPrices.map(y => y.priceGross)))[0];
}

function getLaufzeitFaktor(highestPrice: number, highestPriceDescription: string): number {
    if (!highestPrice) {
        return null;
    }
    if (highestPriceDescription.includes('halbjährlich') || highestPriceDescription.includes('Halbjahr')) {
        return 4;
    }

    return 2;
}
