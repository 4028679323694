$(() => {
  createMerklisteLinks();
});

/**
 * Erstellt den Link für die Merkliste.
 * Wenn Benutzer nicht eingeloggt: auf login-Seite
 * Ansonsten wird Produkt in Merkliste gelegt
 */
function createMerklisteLinks() {
  const merklisteLinks = $("div.productCarousel a.remember");
  const loggedIn = $("#loggedInFlag").length > 0;

  if (loggedIn) {
    addMerklisteForLoggedInUser(merklisteLinks);
  } else {
    addLogInLinkForLoggedOutUser(merklisteLinks);
  }

  function addMerklisteForLoggedInUser(merklisteLinks: JQuery<HTMLElement>) {
    for (let i = 0; i < merklisteLinks.length; i++) {
      const link = merklisteLinks[i];
      $(link).addClass("addToMerkliste");
    }
  }

  function addLogInLinkForLoggedOutUser(merklisteLinks: JQuery<HTMLElement>) {
    for (let i = 0; i < merklisteLinks.length; i++) {
      const link = merklisteLinks[i];
      $(link).attr("href", $(link).data("login") + "?merklisteId=" + $(link).data("id"));
    }
  }
}
