import { trackPrefixNameProblem } from "../Tracking/GoogleTracking";

$(() => {
    /** Passwort anzeigen toogle */
    $('.passwordInputGroup i').on('click', function() {
        var passwordInput = $(this)
            .closest('.passwordInputGroup')
            .find('.passwordInput') as JQuery<HTMLInputElement>;
        if (passwordInput[0].type === 'password') {
            passwordInput[0].type = 'text';
            $(this).removeClass('fa-eye');
            $(this).addClass('fa-eye-slash');
        } else {
            passwordInput[0].type = 'password';
            $(this).removeClass('fa-eye-slash');
            $(this).addClass('fa-eye');
        }
    });

    $('.register-form').on('submit', function (e) {
        const middleName = $(this).find("#MiddleName").val();
        const lastName = $(this).find("#Surname").val();

        if (middleName && !lastName) {
            trackPrefixNameProblem();
        }
    });

    $('.login-form').on('submit', function(e) {
        const spinnerModal = $('#spinnerModal');
        spinnerModal.removeClass('hide');
        $(this).find('button[type=submit]').prop('disabled', true);
    });
});
