declare var dataLayer: any[];
import {
    IAddToCartTracking,
    ITrackingProduct,
    IAddToCartTrackingData,
    AddToCartTrackingModel,
} from '../../Tracking/Models/AddToCartTracking/AddToCartTrackingModel';
import ILineItem = CheckoutflowReponse.ILineItem;
import { getProductNetPrice } from '../Tracking/checkoutTrackingHelper';
import { formatPrice2Digits } from '../../Shared/PriceHelper';
import { getBreadcrumbByTocId } from '../../Shared/ProductHelper';

export function AddToCartTracking(trackingModel: IAddToCartTracking) {
    if (trackingModel) {
        let addToCartPush = JSON.stringify(trackingModel.addToCartPush);
        let econdaPush = JSON.stringify(trackingModel.econdaPush);
        dataLayer.push(JSON.parse(econdaPush));
        dataLayer.push(JSON.parse(addToCartPush));
    }
}

export async function AddToCartTrackingLineItem(lineItem: ILineItem) {
    const price = lineItem.product.prices.filter((x) => x.priceType.priceTypeId ==
        lineItem.currentPriceType.priceTypeId)[0];
    
    let addToCartTrackingData = {
        Products: [
            {
                name: lineItem.product.titleMain,
                id: lineItem.product.productId,
                price: +formatPrice2Digits(getProductNetPrice(lineItem), true),
                brand: lineItem.product.publisher.publisherName,
                category: await getBreadcrumbByTocId(
                    lineItem.product.mainTocNodeId
                ),
                variant: lineItem.product.mediaType.mediaTypeName,
                quantity: 1,
                sku: lineItem.product.isbnIssn,
                comboName: lineItem.product.authorList !== null ?
                    lineItem.product.authorList +
                    ' - ' +
                    lineItem.product.titleMain : lineItem.product.titleMain,
                displayFormat: lineItem.product.displayFormat,
                dimension8: lineItem.product.displayFormat,
                publisherName: lineItem.product.publisher.publisherName,
                availabilityStatusId: lineItem.product.availabilityStatusId,
                isApproximatePrice: price?.isApproximatePrice,
                isNew: lineItem.product.isNew,
                isStandardwerk: lineItem.product.isStandard,
                isTop: lineItem.product.isTopArticle,
                isPreview: lineItem.product.isPreview
            },
        ] as ITrackingProduct[],
    } as IAddToCartTrackingData;
    let addToCartTrackingModel = new AddToCartTrackingModel(
        addToCartTrackingData
    );
    AddToCartTracking(addToCartTrackingModel);
}

export function TrackMiniBasket(miniBasket: any) {
    if (miniBasket) {
        dataLayer.push({
            current_basket: {
                ItemCount: miniBasket.itemCount,
                TotalNet: miniBasket.totalNet?.replace(',', '.'),
                TotalGross: miniBasket.totalGross?.replace(',', '.'),
                Currency: miniBasket.currency,
                ProductIdsCurrentBasket: miniBasket.productIds,
            },
        });
    }
    else {
        dataLayer.push({
            current_basket: {
                ItemCount: 0,
                TotalNet: 0,
                TotalGross: 0,
                Currency: 'EUR',
                ProductIdsCurrentBasket: '',
            },
        });
    }
}

export function TrackBasketOverlayInteraction(label: string) {
    if (label) {
        dataLayer.push({
            event: 'event',
            eventCategory: 'navigation data',
            eventAction: 'meta-cart_interaction',
            eventLabel: label,
        });

        dataLayer.push({
            'event': "econdaEvent",
            'ecCustomProperties': {
                'Target' : ['navigation data', 'meta-cart_interaction' + '/' + label, 0, 'd'], 
                'rqtype' : 'hiddenpi'
            }
        });
    }
}
