$(document).ready(function () {

    $(".bestellform #order_form #clearFormBtn").click(function (e) {
        orderSuccessClose();
        errorClose();
        orderFailedClose();
        orderIncompleteClose();
        orderFailedISBNClose();
        DeleteSearchedISBNS();
        $('#totalOrderPrice').html("");
    });

    $(document).on('input', 'input[name="user_product"]', function () {
        validateIsbn(this);
    });

    $('#isbnList').on('input', '.input-quantity', function () {
        updateTotalOrderPrice();
    });

    // Verknüpfung des Events mit dem Button zur Entfernung von Produkten
    $("#isbnList").on("click", '.btn-remove', removeOrderItem);

    //Validierung des Formulars bei Submit
    $("#submitbtn").click(function (event) {

        event.preventDefault();
        orderSuccessClose();
        errorClose();
        orderFailedClose();
        orderIncompleteClose();
        orderFailedISBNClose();

        if (checkValidity()) {
            var data = getEnBestellformData();

            if (data.orderItems[0].id != null) {
                $.ajax({
                    url: "/Umbraco/api/EnBestellformular/SubmitOrder",
                    data: JSON.stringify(data),
                    dataType: "json",
                    success: orderSuccess(),
                    error: function (statusCode) {
                        error(statusCode.status);
                    },
                    type: "POST",
                    contentType: "application/json; charset=utf-8"
                });
            } else {
                orderFailedISBN();
            } 
        } else {
            orderIncomplete();
        }
        
        
         
    });
});


/**
 * Check ob die Formulardaten ausgefüllt worden sind.
 * Wenn nicht wird eine error klasse gesetzt
 */
function checkValidity() {
    var valid = true;
    $("form[id='order_form'] input").removeClass("inputError");

    if (!$("form[id='order_form']")[0].checkValidity()) {
        valid = false;
        var inputFields = $("form[id='order_form'] input");
        for (var i = 0; i < inputFields.length; i++) {
            if (!inputFields[i].checkValidity()) {
                $(inputFields[i]).addClass("inputError");
            }
        }
    }

    return valid;
}
    /**
     * Aufgerufen nachdem das Formular erfolgreich übermittelt wurde.
     * Leer das Formular und zeigt eine Danknachricht an.
     * @returns {} 
     */
function orderSuccess() {
    emptyForm();
    $('.overlay').show();
};

function error(statusCode) {
    if (statusCode === 500) {
        orderFailed();    
        }
        if (statusCode === 400) {
            orderIncomplete();
        }
    }

    function orderFailed(data) {
        $('.overlayOrderFailed').show();
};

    function orderFailedISBN() {
    $('.overlayISBNFailed').show();
};

    function orderIncomplete(data) {
        $('.overlayOrderIncomplete').show();
};

function orderSuccessClose() {
    $('.overlay').hide();
};

function errorClose() {
        orderIncompleteClose();
        orderFailedClose();
}

function orderFailedISBNClose() {
    $('.overlayISBNFailed').hide();
};

function orderFailedClose() {
    $('.overlayOrderFailed').hide();
};

function orderIncompleteClose() {
    $('.overlayOrderIncomplete').hide();
};

    /**
     * Leert alle Felder des Formulars
     * Leer das Formular und zeigt eine Danknachricht an.
     * @returns {} 
     */
    function emptyForm() {
        $("#order_form").each(function() {
            $(this).val("");
        });
        $("#clearFormBtn").click();

        DeleteSearchedISBNS();

        $('#totalOrderPrice').html("");
        orderSuccessClose();
        errorClose();
        orderFailedClose();
        orderIncompleteClose();
        orderFailedISBNClose();
}

function DeleteSearchedISBNS() {
    var orders = $('.ENItem');
    if ($('.ENItem').length == 1) {
        $(orders[0]).hide();
    }
    var itemLength = $('.ENItem').length;
    for (var i = 0; i < itemLength; i++) {
        if (i > 0) {

            $(orders[i]).remove();
        }
        else {
            $(orders[0]).hide();
            $(orders[0]).data("data-Id", null);
        }
    }
}

    /**
     * Funktion zur Ermittlung des Gesamtpreises
     * @returns {} 
     */
    function updateTotalOrderPrice() {
        var totalPrice = 0;
        var orders = $('.ENItem');
        var showEproxPrice = false;
        
        for (var i = 0; i < $('.ENItem').length; i++) { 
            var productPrice = $(orders[i]).find('.price').text();
            if (productPrice.indexOf("ca.") >= 0) {
                showEproxPrice = true;
            }
            totalPrice += $(orders[i]).data('data-price') * $(orders[i]).find('.input-quantity').val();       
        }
        if (showEproxPrice) {
            $('#totalOrderPrice').html("Total price is ca. " + String(totalPrice.toFixed(2)).replace(".", ",") + " €");
        }
        else {
            $('#totalOrderPrice').html("Total price is " + String(totalPrice.toFixed(2)).replace(".", ",") + " €");
        } 
    }

    /**
    * Funktion zm Update der Produktdaten und der Anzeige dieser
    * @param {} e: Event der eingegebenen gültigen ISBN
    * @param {} data
    * @returns {}
    */
    //e sind die daten aus der API!
    function updateProductData(e, data) {
        var searchProduct = e[0];
        var products = $('#resultWrapper').find('.resultItem');
        var product = products[0];
        var productCloned = false;
        

        if (product.style.display !== "none") {
            var newProduct = $(product).clone();
            product = newProduct;
            productCloned = true;
        }
        //Editieren;
        $(product).data("data-Id", searchProduct.productId);
        $(product).data("data-Isbn", searchProduct.isbn);

        if (!searchProduct.isNew) { $(product).find(".statusNew").hide(); } 
        if (!searchProduct.isTopTitel) { $(product).find(".statusTop").hide(); }
        $(product).find(".productImage").first().attr("src", searchProduct.imageUrl).attr("onerror","this.src = '/images/NoBook_tranparent.png'");
        $(product).find(".publisher").html(searchProduct.authors);
        $(product).find(".title").html(searchProduct.title);
        $(product).find(".description").html(searchProduct.description);
        $(product).find(".availability").html(searchProduct.availability);
        $(product).find(".price").html(searchProduct.caPrice? "ca. ": "" + searchProduct.price); 
        $(product).data("data-price", searchProduct.priceUnformatted);
        $(product).find(".enBestellformularToBasketButton").attr("href",searchProduct.linkToBasket);

        var links = $(product).find(".links").first();
        links.html("");

        for (var i = 0; i < searchProduct.bibliographischeDaten.length; i++) {
            var dateSpan = document.createElement('span');
            dateSpan.innerHTML = searchProduct.bibliographischeDaten[i];
            $(dateSpan).appendTo(links);
        }
        
        $(product).show();
        if (productCloned) {
            product.appendTo("#resultWrapper");
        }

        $('input[name="user_product"]').val("");
        updateTotalOrderPrice();
    }

    /**
     * Funktion zur Anzeige eines Warntextes bei nicht gefundenem Produkt
     * @param {} e: Event der eingegebenen gültigen ISBN
     * @param {} jqxhr
     * @returns {}
     */
// TODO Anpassung des Codes
    function updateErrorHandler(e, jqxhr) {
        //if (jqxhr.status === 404) {
        //    $(e).parent().find(".field-validation-valid").html("No product found for this ISBN").css('display', 'inline');
        //} else {
        //    $(e).parent().find(".field-validation-valid").html("No product found. Server returned Status: " + jqxhr.status).css('display', 'inline');
        //}
    }

    /**
     * Funktion zur Validierung von ISBNs
     * Ruft bei Valider ISBN eine Abfrage von Produktdaten auf
     * @param {} e: Event der eingegebenen gültigen ISBN
     * @returns {}
     */
    function validateIsbn(e) {
        var isbn = e.value.trim();
        var isbnPattern = /^(?:ISBN(?:-1[03])?:? )?(?=[0-9X]{10}$|(?=(?:[0-9]+[- ]){3})[- 0-9X]{13}$|97[89][0-9]{10}$|(?=(?:[0-9]+[- ]){4})[- 0-9]{17}$)(?:97[89][- ]?)?[0-9]{1,5}[- ]?[0-9]+[- ]?[0-9]+[- ]?[0-9X]$/;

        if (isbnPattern.test(isbn)) {
            var url = '/umbraco/api/EnBestellformular/GetProductByISBN';
            $.ajax({
                url: url,
                data: { isbn: isbn},
                dataType: "json",
                success: updateProductData,
                error: updateErrorHandler
            });
        };
    }

/**
 * Funktion zum Formatieren der Währung
 * @param {} n, c, d, t 
 * @returns {} formatierter Währungsstring
 */
// TODO Integration und Anpassung des Codes
function formatMoney(n, c, d, t) {
    //var c = isNaN(c = Math.abs(c)) ? 2 : c,
    //    d = d == undefined ? "," : d,
    //    t = t == undefined ? "." : t,
    //    s = n < 0 ? "-" : "",
    //    i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "",
    //    j = (j = i.length) > 3 ? j % 3 : 0;
    //return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
}



/**
    * Funktion zur Entfernung eines Produkts aus der Liste
    * Setztbei einem einzigen vorhandenen Produkt dessen Feld zurück
    * @param {} e: Event des gedrückten Knopfes
    * @returns {}
    */
// TODO Integration und Anpassung des Codes
function removeOrderItem(e) {
    var ele = $(e.target).closest(".ENItem");
    if (ele["0"].parentNode.childElementCount === 1) {
        $(ele).hide();
        $('#totalOrderPrice').html("");
    } else {
        $(ele).remove();
        updateTotalOrderPrice();
    }
}

/**
 * Funktion zum Auslesen der Produktdaten im Formular
 * zur Erstellung der Email an den Kunden und den Shop
 * @returns {} data
 */
function getEnBestellformData() {
    data = {
        email: $('input[name=user_email]') .val(),
        //confirmEmail: $("#ConfirmEMailTextBox").val(),
        name: $('input[name=user_name]') .val(),
        firstName: $('input[name=user_first_name]') .val(),
        company: $('input[name=user_company]') .val(),
        address: $('input[name=user_address]') .val(),
        zipCode: $('input[name=user_zip]') .val(),
        city: $('input[name=user_city]') .val(),
        country: $('input[name=user_country]') .val(),
        tel: $('input[name=user_tel]') .val(),
        fax: $('input[name=user_fax]') .val(),
        furtherInformation: $('textarea[name=user_information]').val(),
        orderItems: getOrderList(),
        total: $('#totalOrderPrice').text()
    }
    return data;
}

/**
 * Funktion zur Erstellung einer Liste von bestellten Produkten
 * @returns {} array: Array von Produktdaten und Bestellmenge
 */
// TODO Integration und Anpassung des Codes
function getOrderList() {
    var array = [];
    var orders = $('.ENItem');
    for (var i = 0; i < orders.length; i++) {
        var order = {
            quantity: parseInt($(orders[i]).find('.input-quantity').val()),
            id: $(orders[i]).data('data-Id'),
            isbn: $(orders[i]).data('data-Isbn'),
            title: $(orders[i]).find(".title.md.hidden-sm.hidden-xs").text(),
            pricePerUnit: $(orders[i]).data('data-price')
        };
        array.push(order);
    }
    return array;
}

