import { AddToCartPush, IAddToCartPush } from "./IAddToCartPush";
import { EcondaPush, IEcondaPush } from "./IEcondaPush";

export class AddToCartTrackingModel implements IAddToCartTracking{
    constructor(trackingData: IAddToCartTrackingData) {
        this.addToCartPush = new AddToCartPush(trackingData.Products);
        this.econdaPush = new EcondaPush(trackingData.Products);
    }
    addToCartPush: IAddToCartPush;
    econdaPush: IEcondaPush;
}

export interface IAddToCartTracking {
    addToCartPush: IAddToCartPush;
    econdaPush: IEcondaPush;
}

export interface IAddToCartTrackingData {
    Products: ITrackingProduct[];
}

export interface ITrackingProduct {
    name: string;
    id: number;
    price: number;
    brand: string;
    category: string;
    variant: string;
    quantity: number;
    sku: string;
    comboName: string;
    displayFormat: string;
    publisherName: string;
    dimension8: string;
    availabilityStatusId: number;
    isApproximatePrice: boolean;
    isNew: boolean;
    isStandardwerk: boolean;
    isTop: boolean;
    isPreview: boolean;
}



