import { MobileNavApiResponeModel } from './Models/mobileNaviApiResponse';
import { MobileNavBuilder } from './mobileNavBuilder';

const navHelper = new MobileNavBuilder();
$(() => {
    $('ul.mobMenu.mobileNavigation').on('click', 'a.mobNavLink', function(e) {
        updateMobileNavi(e, this);
    });

    $('ul.mobMenu.mobileNavigation').on('click', '.backLink', function(e) {
        updateMobileNavi(e, this);
    });

    $('ul.mobMenu.mobileNavigation').on('click', '.mobile-menu-breadcrumb-root', function(e) {
        console.log('click');

        updateMobileNavi(e, this);
    });
});

function updateMobileNavi(e, target: HTMLElement) {
    if ($(target).children('i').length || $(target).hasClass('mobile-menu-breadcrumb-root')) {
        e.preventDefault();
        $.ajax({
            method: 'GET',
            url: `/Umbraco/api/MobileNavi/GetNavItems?id=${$(target).data('id')}&weitere=${$(target).data(
                'weitere'
            )}&fachbereichDetail=${$(target).data('fachbereich')}`,
            success: data => {
                var response = data as MobileNavApiResponeModel;
                navHelper.buildMobileNavi(response);
            },
            error: data => {},
        });
    }
}
