import { trackMerklisteInteraction_click } from "../Tracking/GoogleTracking";
import { ShoppingListService } from "./shoppingListService";
const shoppingListService = new ShoppingListService();

$(() => {

    /*Bei jeden Seitenaufruf soll die aktuelle Anzahl der Items geldaden werden (nur wenn eingeloggt) */
    if ($("#loggedInFlag").length > 0) {
        shoppingListService.updateShoppingListCount();
    }
    /**
     * Wird getriggert, wenn auf den entfernen Button geklickt wird.
     */
    $(".removeShoppingListItem").click((e) => {
        var productId = $(e.target).data("id");
        if (productId) {
            shoppingListService.deleteItem(productId, (xhr, status) => {
                if (xhr.status === 200) {
                    $(e.target).closest(".resultItem").fadeOut('slow', function () {
                        $(this).remove();
                    });
                    trackMerklisteInteraction_click("interaction_remove-from-wishlist");
                }
            });
        }
    });

    $(".shoppingListItemClick").click((e) => {
        trackMerklisteInteraction_click("interaction_product-click-from-wishlist");
    });

    $(".shoppingListItemAddToBasket").click((e) => {
        trackMerklisteInteraction_click("interaction_add-to-cart-from-wishlist");
    });

    /**
     * Wird getriggert, wenn auf einen Button zum hinzufügen eines Produkts auf die Merkliste geklickt wird
     */
    $(document).on('click', '.addToMerkliste', function () {
        var productId = $(this).data("id");
        if (productId) {
            $("#spinnerModal").removeClass("hide");
            shoppingListService.addItem(productId);
        }

    });
})