import { ProduktkarussellItem, ProduktkarussellModel } from '../Produktdetails/Models/Produktkarussell';

export const CreateSliderContent = (
    products: ProduktkarussellModel,
    container,
    sliderPrototype,
    sliderId,
    trbotracking: boolean = false
): void => {
    var container = container;
    if (!nullOrEmpty(products) && !nullOrEmpty(products.karussellItems) && products.karussellItems.length > 0) {
        var sliderPrototypes = sliderPrototype.clone();
        for (let product of products.karussellItems) {
            var productItem = sliderPrototypes.find('.productItem').clone();
            //Labels
            var labelWrapper = productItem.find('.labelWrapper');
            if (product.isNew) {
                var newLabel = sliderPrototypes.find('.statusLabel.red').clone();
                newLabel.find('.txt').html('Neu');
                labelWrapper.append(newLabel);
            }
            if (product.isTopTitel) {
                var topLabel = sliderPrototypes.find('.statusLabel.gold').clone();
                topLabel.find('.txt').html('Toptitel');
                labelWrapper.append(topLabel);
            }
            if (product.isStandardwerk) {
                var standardwerkLabel = sliderPrototypes.find('.statusLabel.gold').clone();
                standardwerkLabel.find('.txt').html('Standardwerk');
                labelWrapper.append(standardwerkLabel);
            }
            if (product.isAnkuendigung) {
                var ankuendigungLabel = sliderPrototypes.find('.statusLabel.red').clone();
                ankuendigungLabel.find('.txt').html('Ankündigung');
                labelWrapper.append(ankuendigungLabel);
            }
            if (product.isEbook) {
                var ebookLabel = sliderPrototypes.find('.mediaTypeLabel.gold').clone();
                ebookLabel.html('eBook');
                productItem.find('.imgWrapper').append(ebookLabel);
            }
            if (sliderId == 'merklisteSlider') {
                productItem.find('.title a').addClass('merklisteTracking');
            }
            if (container[0].id == 'productTopReihen') {
                productItem
                    .find('.title a')
                    .html(product.title)
                    .attr('href', product.productDetailUrl)
                    .addClass('topProductTracking')
                    .attr('data-trackingevent', 'PDP_top-products_static-reco');
            } else if (container[0].id == 'productTrbo' || container[0].id == 'productTrboLastSeen' || trbotracking) {
                productItem
                    .find('.title a')
                    .html(product.title)
                    .attr('href', product.productDetailUrl)
                    .addClass('trboClickTracking')
                    .attr('data-campaignid', products.campaignId)
                    .attr('data-campaignname', products.campaignName)
                    .attr('data-moduleid', products.moduleId)
                    .attr('data-modulename', products.moduleName)
                    .attr('data-productid', product.productId)
                    .attr('data-usertype', products.userType);
            } else {
                productItem
                    .find('.title a')
                    .html(product.title)
                    .attr('href', product.productDetailUrl);
            }

            productItem.find('.author').html(product.authorstring);
            if (product.pricePrefix === 'ca. ') {
                productItem.find('.price .ca').append(product.pricePrefix + product.price);
            } else {
                productItem.find('.price .ca').remove();
                productItem.find('.price').append(product.pricePrefix + product.price);
            }

            productItem.find('.imgWrapper img').attr('src', product.imageUrl);
            productItem.find('.imgWrapper img').attr('alt', `Cover ${product.title}`);
            productItem.find('.imgWrapper').attr('href', product.productDetailUrl);
            if (container[0].id == 'productTopReihen') {
                productItem
                    .find('.imgWrapper')
                    .addClass('topProductTracking')
                    .attr('data-trackingevent', 'PDP_top-products_static-reco');
                productItem.find('.addToCard').addClass('promotionClick');
            } else if (container[0].id == 'productTrbo' || container[0].id == 'productTrboLastSeen' || trbotracking) {
                productItem
                    .find('.imgWrapper')
                    .addClass('trboClickTracking')
                    .attr('data-campaignid', products.campaignId)
                    .attr('data-campaignname', products.campaignName)
                    .attr('data-moduleid', products.moduleId)
                    .attr('data-modulename', products.moduleName)
                    .attr('data-productid', product.productId)
                    .attr('data-usertype', products.userType);
            } else if (container[0].id == 'productFamilyContainer') {
                productItem.find('.imgWrapper').addClass('promotionClick');
                productItem.find('.remember').addClass('promotionClick');
                productItem.find('.title').addClass('promotionClick');
                productItem.find('.addToCard').addClass('promotionClick');
            }

            if (sliderId == 'merklisteSlider') {
                productItem.find('.imgWrapper').addClass('merklisteTracking');
            }

            if (product.warenkorbAnzeigen && product.isBeckOnline !== true) {
                let addToCartButton = productItem.find('.addToCard.shop');
                if (sliderId == 'merklisteSlider') {
                    addToCartButton.addClass('merklisteTracking');
                }
                addToCartButton.attr('data-productid', product.productId);
                if (product.showBezugsartOverlay) {
                    addToCartButton.addClass('ajax_checkout_bezugsart');
                    addToCartButton.attr('data-toggle', 'modal').attr('data-target', '#modalBezugsart-01');
                } else {
                    addToCartButton.addClass('ajax_checkout_add');
                }
                if (container[0].id == 'productTrbo' || container[0].id == 'productTrboLastSeen' || trbotracking) {
                    addToCartButton
                        .addClass('trboClickTracking')
                        .attr('data-campaignid', products.campaignId)
                        .attr('data-campaignname', products.campaignName)
                        .attr('data-moduleid', products.moduleId)
                        .attr('data-modulename', products.moduleName)
                        .attr('data-productid', product.productId)
                        .attr('data-usertype', products.userType);

                    productItem
                        .find('.remember')
                        .addClass('trboClickTracking')
                        .attr('data-campaignid', products.campaignId)
                        .attr('data-campaignname', products.campaignName)
                        .attr('data-moduleid', products.moduleId)
                        .attr('data-modulename', products.moduleName)
                        .attr('data-productid', product.productId)
                        .attr('data-usertype', products.userType);
                }
                productItem.find('.addToCard.screen').addClass('hide');
            } else {
                productItem.find('.addToCard').addClass('hide');
            }

            if (product.isBeckOnline) {
                productItem.find('.addToCard.screen').removeClass('hide');
                productItem.find('.addToCard.screen').attr('data-productid', product.productId);
                productItem
                    .find('.addToCard.screen')
                    .attr(
                        'href',
                        `https://shop.beck-online.de/?crop=${product.kupOrderNo}&chk=${
                            product.kupOrderNo
                        }&usr=1&cid=BS&uid=`
                    )
                    .attr(
                        'data-price',
                        product.price
                            .replace('€', '')
                            .replace(',', '.')
                            .trim()
                    );
            }

            productItem.find('.remember').attr('data-id', product.productId);
            if ($('#loggedInFlag').length) {
                productItem.find('.remember').addClass('addToMerkliste');
            } else {
                productItem
                    .find('.remember')
                    .attr(
                        'href',
                        products.loginPageUrl
                            ? products.loginPageUrl + '?merklisteId=' + product.productId
                            : '/meine-daten/meine-daten-login/' + '?merklisteId=' + product.productId
                    );
            }

            if (
                product.lieferstatusId !== 3 &&
                product.lieferstatusId !== 5 &&
                !product.lieferstatus.includes('vorbestell')
            ) {
                productItem.find('.reminder').addClass('hide');
            } else {
                productItem.find('.reminder').attr('data-id', product.productId);
            }

            getAvailabilityInfoText(product, productItem);
            getEditionText(product, productItem);

            container.append(productItem);
        }

        addContainerViewTracking(container, trbotracking, products);
        $('.productFamily.ajaxSpinner_Loading').addClass('hide');
    } else {
        if (container[0].id == sliderId) {
            var topProductsKarussel = $('slider');
            topProductsKarussel.addClass('hide');
        }
    }
};

function addContainerViewTracking(container: any, trbotracking: boolean, products: ProduktkarussellModel) {
    if (container[0].id == 'productTrbo' || container[0].id == 'productTrboLastSeen' || trbotracking) {
        container
            .addClass('trboPromotionTracking')
            .attr('data-campaignid', products.campaignId)
            .attr('data-campaignname', products.campaignName)
            .attr('data-moduleid', products.moduleId)
            .attr('data-modulename', products.moduleName)
            .attr('data-usertype', products.userType);
    } else if (container[0].id == 'productTopReihen') {
        container
            .addClass('googleTracking')
            .addClass('promotionTracking')
            .attr('data-trackingevent', 'PDP_top-products_static-reco');
    } else if (container[0].id == 'productFamilyContainer') {
        container
            .addClass('googleTracking')
            .addClass('promotionTracking')
            .attr('data-trackingevent', 'PDP_product-family_static-reco');
    }
}

function getAvailabilityInfoText(product: ProduktkarussellItem, productItem: any) {
    if (product.lieferstatus && product.lieferstatus.length > 0) {
        if (
            product.lieferstatusId === 3 &&
            product.dateOfPublicationDescription &&
            product.dateOfPublicationDescription.length > 0
        ) {
            productItem
                .find('.lieferStatus span')
                .html(`${product.lieferstatus} (${product.dateOfPublicationDescription})`);
        } else {
            productItem.find('.lieferStatus span').html(product.lieferstatus);
            if (
                product.lieferstatusId === 1 ||
                product.lieferstatusId === 4 ||
                product.lieferstatus === 'sofort lieferbar!'
            ) {
                productItem.find('.lieferStatus span').addClass('availabilityStatusColorGreen');
            }
        }
    }
}

function nullOrEmpty(element) {
    return element === null || element === '' || typeof element === 'undefined';
}
function getEditionText(product: ProduktkarussellItem, productItem: any) {
    if (product.auflage) {
        productItem.find('.edition span').html(product.auflage);
    }
}
