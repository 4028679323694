import { CheckoutService } from './checkoutService';
const checkoutService = new CheckoutService();

export const recaptchaCallbackGuest = function(response) {
    checkoutService.getSaferpayIframe(response).then(response => {
        const iframeUrl = response as string;
        if (iframeUrl) {
            $('.kreditkartenIframeWrapper iframe').attr('src', response as string);
            $('.recpatcha-container').hide();
            $('.cc-iframe-container').removeClass('hide');
        }
    });
};
