

export const buildTrboRequestUrl = (slot, productId) => {
    let requestUrl = 'https://api-v4.trbo.com/s2s.php';

    requestUrl +=
        '?g=' + JSON.stringify(parseGlobalData());
    requestUrl +=
        '&s=' + JSON.stringify(parseSessionData());
    requestUrl +=
        '&p=' + JSON.stringify(parseProductData(productId));
    requestUrl +=
        '&dl=' +
        JSON.stringify(parseDataLayerData(slot));
    requestUrl +=
        '&seg=' +
        JSON.stringify(parseSegments());

    if (Cookies.get("trbodebug")) {
        console.log(requestUrl);
    }
    return requestUrl;
}

const parseSegments = function() {
    var segments = {};
    if (typeof window !== 'undefined' && window && window._trbo) {
        segments = window._trbo.udp.data.segmentData;
    }

    return segments;
}

const parseGlobalData = function() {
    var global_data = {
      st: 0,
      p: encodeURIComponent(window.location.href),
        pt: encodeURIComponent(window.location.href), // current URL with URL parameters
        t: Math.floor((new Date()).getTime() / 1000),
        sh: 13017, // shop_id from trbo interface : TODO 
        u: get_user_id(), // trbo user_id
        ts: get_sale_count(), // sale count number of sales per User
        ust: get_user_start_time(), // user start time
        sdt: get_session_duration_total(), // session duration total of all sessions in seconds
        sc: get_session_count(), // session count, number of total sessions
        sid: get_session_id(),
        pt: get_page_type_id(),
        cb: get_current_basket(),
        bw: 1440, // browser / viewport with
        bh: 768, // browser / viewport height
        cic: [], // click in channel - legacy
        cic2: get_cic2(), // click in channel v2
        ref: "", // page referrer
    };

    return global_data;
};

const parseSessionData = function() {
    var session_data = [];
    // if window._trbo is available :
    if (typeof window !== 'undefined' && window && window._trbo) {
        session_data = window._trbo.udp.getSessions().slice(0,5);
    } else if (typeof localStorage !== 'undefined') {
        // else use local storage
        session_data.push({
            sst: JSON.parse(localStorage._trbo_ud).session.createTime,
            pvc: JSON.parse(localStorage._trbo_ud).session.sessionSummary.pageViewCount,
            sd: JSON.parse(localStorage._trbo_ud).session.sessionSummary.sessionDuration
        });
        var passt_sessions = JSON.parse(localStorage._trbo_ud).sessions;
        if (Object.keys(passt_sessions).length > 0) {
            for (var sessionkey in passt_sessions) {
                if (session_data.length >= 5) continue;
                session_data.push({
                    sst: passt_sessions[sessionkey].createTime,
                    pvc: passt_sessions[sessionkey].sessionSummary.pageViewCount,
                    sd: passt_sessions[sessionkey].sessionSummary.sessionDuration 
                });
            }
        }
    }

    return session_data;
};

const parseProductData = function(productId) {
    var product_data = {};

    if (productId) {
        product_data = {
            [productId]: Date.now()
        };

        return product_data;
    }

    
    // if window._trbo is available :
    if (typeof window !== 'undefined' && window && window._trbo) {
        product_data = window._trbo.app.opts.requestDataTransformer.getProducts()
    } else if (typeof localStorage !== 'undefined') {
    }

    return product_data;
};

const parseDataLayerData = function(slot) {
    var data_layer = {};
   
    if (typeof window !== 'undefined' && window && window._trbo) {
        var _local_storage_data_layer = _trbo.udp.getUserSummary().externalData;
        if (_local_storage_data_layer && Object.keys(_local_storage_data_layer).length > 0) {
            for (var data_layer_key in _local_storage_data_layer) {
                if (_local_storage_data_layer[data_layer_key] !== 'n/a' && 
                    _local_storage_data_layer[data_layer_key] !== '' &&  
                    data_layer_key !== 'external_user_id') {
                    if (typeof _local_storage_data_layer[data_layer_key] === 'object' && 
                        _local_storage_data_layer[data_layer_key] !== null) {
                        data_layer[data_layer_key] = _local_storage_data_layer[data_layer_key]
                    }
                    else {
                        data_layer[data_layer_key] = _local_storage_data_layer[data_layer_key].toString().replace("&", "%26");
                    }
                    
                }
            }
        }
    }
    
    if (slot) {
        data_layer["slot"] = slot;
    }

    return data_layer;
};

/**
 * unique trbo user id
 */
const get_user_id = function() {
    return JSON.parse(localStorage._trbo_ud).user.userId;;
};

/**
 * current session id of user
 */
const get_session_id = function() {
    return JSON.parse(localStorage._trbo_ud).session.sessionId;
};

/**
 * id of current page type
 */
const get_page_type_id = function() {
    var page_type_mapping = {
        'detail': 1,
        'search': 2,
        'basket': 3,
        'home': 4,
        'other': 5,
        'checkout': 6,
        'static': 7,
        'category': 8,
        'unknown': 9,
        'confirmation': 10,
        'account': 12
    };
    var page_type_id = page_type_mapping.detail;
    // TODO return correct page type

    return page_type_id;
};

/**
 * value, number and items of current basket
 */
const get_current_basket = function() {
    var current_basket_data = {
        value: 0, // absolut value of current basket
        num: 0, // number of products within current basket
        voucher: 0, // is voucher within basket : 0 = no : 1 = yes
        p: [] // array of product_ids within current basket 
    };
    // TODO fill data
    return current_basket_data;
};


/**
 * total number of sale conversions
 */
const get_sale_count = function() {
    return JSON.parse(localStorage._trbo_ud).user.userSummary.saleCount;
};

/**
 * timestamp when user was created 
 */ 
const get_user_start_time = function() {
    return JSON.parse(localStorage._trbo_ud).user.userSummary.userCreateTime;
};

/**
 * total session duration from all sessions in seconds
 */
const get_session_duration_total = function() {
    return JSON.parse(localStorage._trbo_ud).user.userSummary.sessionDurationTotal;
};

/**
 * get number of sessions for this user
 */
const get_session_count = function() {
    return JSON.parse(localStorage._trbo_ud).user.userSummary.sessionCount;
};

const get_cic2 = function() {
    let cic2 = [];

    if (typeof window !== 'undefined' && window && window._trbo) {
        cic2 = window._trbo.udp.getChannelData()
    } 

    return cic2;
}
