declare var Swiper: any;
var inAdvance = 700;

var setSlideGroupBig, setSlideGroupSmall;
var carouselbig = [];
var carouselsmall = [];
var karussellElementsLarge: JQuery;
var karussellElementsSmall: JQuery;
var swiperElements: JQuery;
$(() => {
    checkForSwiperSlider();

    let windowWidth = $(window).width();
    if (windowWidth <= 768) {
        setSlideGroupBig = 1;
        setSlideGroupSmall = 1;
    } else if (windowWidth <= 1200) {
        setSlideGroupBig = 3;
        setSlideGroupSmall = 1;
    } else {
        setSlideGroupBig = 4;
        setSlideGroupSmall = 3;
    }

    //Produktkarussell
    karussellElementsLarge = $('[id^=produktkarussellLarge]:not(.initialized):not(.trboDynamicSlider)');
    karussellElementsSmall = $('[id^=produktkarussellSmall]:not(.initialized):not(.trboDynamicSlider)');
    lazyloadKarussell();

    window.addEventListener('scroll', function() {
        lazyloadKarussell();
    });

    /**
     * Beim window resize müssen die Produktkarussel aktualisiert werden,
     * ansonsten passt die Darstellung nicht mehr
     */
    $(window).resize(() => {
        for (var j = 0; j < carouselbig.length; j++) {
            carouselbig[j].pagination.render();
            carouselbig[j].pagination.update();
            carouselbig[j].update();
        }
        for (var k = 0; k < carouselsmall.length; k++) {
            carouselsmall[k].pagination.render();
            carouselsmall[k].pagination.update();
            carouselsmall[k].update();
        }
    });
});

function lazyloadKarussell() {
    for (let index = 0; index < karussellElementsLarge.length; index++) {
        try {
            const karussell = karussellElementsLarge[index];
            let karussellTop = karussell.getBoundingClientRect().top;
            if (karussellTop < window.innerHeight + window.pageYOffset + inAdvance && karussellTop > 0) {
                carouselbig.push(initSwiperLarge(karussell));
                karussell.classList.add('initialized');
            }
        } catch (error) {
            console.error(error);
        }
    }
    for (let index = 0; index < karussellElementsSmall.length; index++) {
        try {
            const karussell = karussellElementsSmall[index];
            let karussellTop = karussell.getBoundingClientRect().top;
            if (karussellTop < window.innerHeight + window.pageYOffset + inAdvance && karussellTop > 0) {
                carouselsmall.push(initSwiperSmall(karussell));
                karussell.classList.add('initialized');
            }
        } catch (error) {
            console.error(error);
        }
    }
    karussellElementsLarge = karussellElementsLarge.filter(':not(.initialized)');
    karussellElementsSmall = karussellElementsSmall.filter(':not(.initialized)');
}

/**
 * Überprüft ob es auf der Seite Slider gibt, falls ja, werden diese konfiguriert
 */
function checkForSwiperSlider() {
    swiperElements = $('[id^=bannerSwiper]:not(.trboDynamicBuehne)');
    var frequency = swiperElements.first().data('frequency');
    if (swiperElements.length > 0) {
        configureSwiperSlider(frequency, 'fade', swiperElements);
    }
}

/**
 * Konfiguriert alle Swiper Slider auf der Seite
 * @param autoplay Die Anzeigedauer eines Bildes
 * @param effect Der Transition effekt
 * @param elements Die Swiper slider Elemente
 */
export function configureSwiperSlider(autoplay: number, effect: string, elements: any) {
    for (var i = 0; i < elements.length; i++) {
        var swiper = new Swiper(elements[i] as any, {
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            loop: true,
            autoplay: {
                delay: autoplay,
            },
            autoplayDisableOnInteraction: true,
            effect: effect,
        });
    }
}

/**
 * Initialisierungsfunktion für das kleine karussel
 * @param elem
 */
export function initSwiperSmall(elem: any) {
    let swiper = new Swiper(elem, {
        slidesPerView: setSlideGroupSmall,
        spaceBetween: 30,
        slidesPerGroup: setSlideGroupSmall,
        loop: true,
        loopFillGroupWithBlank: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        observer: true,
        observeParents: true,
        breakpoints: {
            1200: {
                slidesPerView: 2,
                spaceBetween: 40,
            },
            1024: {
                slidesPerView: 2,
                spaceBetween: 40,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            640: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            320: {
                slidesPerView: 1,
                spaceBetween: 10,
            },
        },
        on: {
            resize: function() {
                if ($(window).width() > 1200) {
                    setSlideGroupSmall = 3;
                    this.params.slidesPerView = 3;
                } else if ($(window).width() < 1200 && $(window).width() > 1024) {
                    setSlideGroupSmall = 2;
                    this.params.slidesPerView = 2;
                } else if ($(window).width() < 1024) {
                    this.params.slidesPerView = 1;
                    setSlideGroupSmall = 1;
                }
                this.params.slidesPerGroup = setSlideGroupSmall;
                this.update();
                this.pagination.update();
                this.pagination.render();
            },
        },
    });
    $(elem)
        .find('.swiper-wrapper')
        .removeClass('hide');
    return swiper;
}

/**
 * Initialisierungsfunktion für das große karussel
 * @param elem
 */
export function initSwiperLarge(elem: any) {
    let swiper = new Swiper(elem, {
        slidesPerView: setSlideGroupBig,
        spaceBetween: 30,
        slidesPerGroup: setSlideGroupBig,
        loop: false,
        loopFillGroupWithBlank: false,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        observer: true,
        observeParents: true,
        breakpoints: {
            //1400: {
            //    slidesPerView: 4,
            //    spaceBetween: 30
            //},
            1200: {
                slidesPerView: 4,
                spaceBetween: 40,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            640: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            320: {
                slidesPerView: 1,
                spaceBetween: 10,
            },
        },
        on: {
            resize: function() {
                if ($(window).width() > 1200) {
                    setSlideGroupBig = 4;
                    this.params.slidesPerView = 4;
                } else if ($(window).width() < 1200 && $(window).width() > 1024) {
                    setSlideGroupBig = 3;
                    this.params.slidesPerView = 3;
                } else if ($(window).width() < 1024 && $(window).width() > 768) {
                    this.params.slidesPerView = 2;
                    setSlideGroupBig = 3;
                } else {
                    this.params.slidesPerView = 1;
                    setSlideGroupBig = 1;
                }
                this.params.slidesPerGroup = setSlideGroupBig;
                this.update();
                this.pagination.update();
                this.pagination.render();
            },
        },
    });
    $(elem)
        .find('.swiper-wrapper')
        .removeClass('hide');
    return swiper;
}
