import { ITrackingProduct } from './AddToCartTrackingModel';

export class AddToCartPush implements IAddToCartPush {
    constructor(addTCartPushProducts: ITrackingProduct[]) {
        this.event = 'eec.addToCart';
        this.ecommerce = {} as IEcommerce;
        this.ecommerce.currencyCode = 'EUR';
        this.ecommerce.add = {} as IAdd;
        this.ecommerce.add.products = mapAddToCartPushProducts(addTCartPushProducts);
    }
    event: string;
    ecommerce: IEcommerce;
}

export interface IAddToCartPush {
    event: string;
    ecommerce: IEcommerce;
}

class AddToCartPushProduct {
    constructor(trackingProduct: ITrackingProduct) {
        this.name = trackingProduct.name;
        this.id = trackingProduct.id;
        this.price = trackingProduct.price;
        this.brand = trackingProduct.brand;
        this.category = trackingProduct.category;
        this.variant = trackingProduct.variant;
        this.quantity = trackingProduct.quantity;
        this.dimesion8 = trackingProduct.displayFormat;
    }
    name: string;
    id: number;
    price: number;
    brand: string;
    category: string;
    variant: string;
    quantity: number;
    dimesion8: string;
}

export interface IAddToCartPushProduct {
    name: string;
    id: number;
    price: number;
    brand: string;
    category: string;
    variant: string;
    quantity: number;
    dimesion8: string;
}

interface IEcommerce {
    currencyCode: string;
    add: IAdd;
}

interface IAdd {
    products: IAddToCartPushProduct[];
}

function mapAddToCartPushProducts(trackingProducts: ITrackingProduct[]): IAddToCartPushProduct[] {
    let addToCartPushProducts = [] as IAddToCartPushProduct[];
    trackingProducts.forEach((trackingProduct: ITrackingProduct) => {
        addToCartPushProducts.push(new AddToCartPushProduct(trackingProduct));
    });
    return addToCartPushProducts;
}
