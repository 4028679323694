$(() => {
    var lazyloadImages = document.querySelectorAll<HTMLImageElement>("img.lazy-image, div.lazy-image");
    var inAdvance = 100;
    lazyload()

    function lazyload() {

        for (let index = 0; index < lazyloadImages.length; index++) {
            try {
                const img = lazyloadImages[index];
                if (img.getBoundingClientRect().top < (window.innerHeight + window.pageYOffset + inAdvance) && img.getBoundingClientRect().top > 0) {
                    if (img.dataset.src) {
                        if (img.dataset.sizeoptions) {
                            img.src = getFittingImage(img.dataset.sizeoptions);
                        } else {
                            img.src = img.dataset.src;
                            if (img.dataset.srcset) {
                                img.srcset = img.dataset.srcset;
                            }
                            if (img.dataset.sizes) {
                                img.sizes = img.dataset.sizes;
                            }
                        }
                    }
                    else if (img.dataset.background) {
                        let image = getFittingImage(img.dataset.sizeoptions);
                        if (image) {
                            img.style.backgroundImage = "url(" + image + ")";
                        } else {
                            img.style.backgroundImage = "url(" + img.dataset.background + ")";
                        }
                    }

                    img.classList.remove("lazy-image");
                }
            } catch (error) {
                console.error(error);
            }
        }
        lazyloadImages = document.querySelectorAll<HTMLImageElement>("img.lazy-image, div.lazy-image");
    }
    document.addEventListener("scroll", lazyload);
    window.addEventListener("resize", lazyload);
    window.addEventListener("orientationChange", lazyload);
})

export function getFittingImage(sizeOptions) {
    if (!sizeOptions) {
        return null;
    }
    let backgroundOptions = JSON.parse(sizeOptions) as [];
    let windowWidth = window.innerWidth;
    let keys = (Object.keys(backgroundOptions).map(x => +x));
    let filteredKeys = keys.filter(key => key >= windowWidth);
    let key;
    if (!filteredKeys || filteredKeys.length == 0) {
        key = 0; // Default value
    } else {
        key = filteredKeys[0];
    }
    return backgroundOptions[key];
}