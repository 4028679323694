import { recaptchaCallbackGuest } from '../Checkout/checkoutRecaptchaCallback';

declare var UC_UI: any;
declare var grecaptcha: any;

export const recaptchaId = 'Hko_qNsui-Q';
const googleMapsId = 'S1pcEj_jZX';
const youtubeId = 'BJz7qNsdj-7';
const yumpuId = '6LdBYXdAl';
const trboId = 'ryxCCtrEX';
const podigeeId = 'nhLMP6qX';

const createPlaceholderHtml = (templateId: string): string => {
    return `
    <div class="ucConsent contentCtrls ${templateId}">
        <p class="heading">Wir benötigen Ihre Zustimmung, um einen Service zu laden!</p>
        <p>
            Wir verwenden an dieser Stelle einen Service, um Inhalte einzubetten. 
            Dieser Service kann Daten zu Ihren Aktivitäten sammeln. Bitte lesen Sie die Details durch und stimmen Sie der Nutzung des Service zu, um diese Inhalte anzuzeigen.
        </p>
        <a class="ucAgree loadMoreBtn" onclick="UC_UI.acceptService('${templateId}'); return false;">Zustimmen</button>
        <a class="moreInfo" onclick="UC_UI.showSecondLayer('${templateId}'); return false;">Mehr Informationen</button>
    </div>`;
};

const checkForYouTubeIframe = (iframe: HTMLElement, youTubeAllowed: boolean) => {
    const ucSrc = $(iframe).attr('uc-src');
    if (ucSrc && ucSrc.indexOf('youtube') !== -1) {
        if (youTubeAllowed) {
            $(iframe).attr('src', $(iframe).attr('uc-src'));
        } else {
            const parent = $(iframe).parent();
            $(createPlaceholderHtml(youtubeId)).insertAfter(iframe);
            $(iframe).hide();
        }
    }
};

const checkForGoogleMapsIframe = (iframe: HTMLElement, googleMapsAllowed: boolean) => {
    const ucSrc = $(iframe).attr('uc-src');
    if (ucSrc && ucSrc.indexOf('google.com/maps') !== -1) {
        if (googleMapsAllowed) {
            $(iframe).attr('src', $(iframe).attr('uc-src'));
        } else {
            const parent = $(iframe).parent();
            $(createPlaceholderHtml(googleMapsId)).insertAfter(iframe);
            $(iframe).hide();
        }
    }
};

const checkForYumpuIframe = (iframe: HTMLElement, yumpuAllowed: boolean) => {
    const ucSrc = $(iframe).attr('uc-src');
    if (ucSrc && (ucSrc.indexOf('yumpu') !== -1 || ucSrc.indexOf('neuheiten.beck') !== -1)) {
        if (yumpuAllowed) {
            $(iframe).attr('src', $(iframe).attr('uc-src'));
        } else {
            const parent = $(iframe).parent();
            $(createPlaceholderHtml(yumpuId)).insertAfter(iframe);
            $(iframe).hide();
        }
    }
};

const checkForPodigeeIframe = (iframe: HTMLElement, podigeeAllowed: boolean) => {
    const ucSrc = $(iframe).attr('uc-src');
    if (ucSrc && ucSrc.indexOf('podigee') !== -1) {
        if (podigeeAllowed) {
            $(iframe).attr('src', $(iframe).attr('uc-src'));
        } else {
            const parent = $(iframe).parent();
            $(createPlaceholderHtml(podigeeId)).insertAfter(iframe);
            $(iframe).hide();
        }
    }
};

export const checkForRecaptcha = (recaptchaAllowed: boolean, lazy: boolean = false, containerClass: string = null) => {
    let className = lazy ? '.g-recaptcha-uc-lazy' : '.g-recaptcha-uc';
    if (containerClass) {
        className = containerClass;
    }
    if (!recaptchaAllowed) {
        $(className).each(function() {
            $(this).empty();
            $(this).html(createPlaceholderHtml(recaptchaId));
        });
    } else {
        try {
            grecaptcha.ready(function() {
                $(className).each(function() {
                    try {
                        const sitekey = $(this).data('sitekey');
                        const ccGuestCallback = $(this).data('cc-guest-callback');
                        let widgetId = grecaptcha.render(this, {
                            sitekey: sitekey,
                            callback: ccGuestCallback === true ? recaptchaCallbackGuest : null,
                        });
                        $(this).data('widget', widgetId);
                    } catch {}
                });
            });
        } catch {}
    }
};

const showGoogleMapsIframe = (iframe: HTMLElement) => {
    const ucSrc = $(iframe).attr('uc-src');
    if (ucSrc && ucSrc.indexOf('google.com/maps') !== -1) {
        $(iframe).attr('src', $(iframe).attr('uc-src'));
        $(iframe).show();
        $('.' + googleMapsId).remove();
    }
};

const showYoutubeIframe = (iframe: HTMLElement) => {
    const ucSrc = $(iframe).attr('uc-src');
    if (ucSrc && ucSrc.indexOf('youtube') !== -1) {
        $(iframe).attr('src', $(iframe).attr('uc-src'));
        $(iframe).show();
        $('.' + youtubeId).remove();
    }
};

const showYumpuIframe = (iframe: HTMLElement) => {
    const ucSrc = $(iframe).attr('uc-src');
    if (ucSrc && (ucSrc.indexOf('yumpu') !== -1 || ucSrc.indexOf('neuheiten.beck') !== -1)) {
        $(iframe).attr('src', $(iframe).attr('uc-src'));
        $(iframe).show();
        $('.' + yumpuId).remove();
    }
};

const showPodigeeIframe = (iframe: HTMLElement) => {
    const ucSrc = $(iframe).attr('uc-src');
    if (ucSrc && ucSrc.indexOf('podigee') !== -1) {
        $(iframe).attr('src', $(iframe).attr('uc-src'));
        $(iframe).show();
        $('.' + podigeeId).remove();
    }
};

const showrecaptcha = (lazy: boolean = false) => {
    const className = lazy ? '.g-recaptcha-uc-lazy' : '.g-recaptcha-uc';
    $('.' + recaptchaId).remove();
    try {
        $(className).each(function() {
            const sitekey = $(this).data('sitekey');
            const ccGuestCallback = $(this).data('cc-guest-callback');
            let widgetId = grecaptcha.render(this, {
                sitekey: sitekey,
                callback: ccGuestCallback === true ? recaptchaCallbackGuest : null,
            });
            $(this).data('widget', widgetId);
        });
    } catch {}
};

const initUserCentrics = () => {
    if (window['UC_UI'] !== undefined && UC_UI.isInitialized) {
        const youTubeAllowed = UC_UI.getServicesBaseInfo().find(service => service.id === youtubeId).consent.status;
        const googleMapsAllowed = UC_UI.getServicesBaseInfo().find(service => service.id === googleMapsId).consent
            .status;
        const recaptchaAllowed = UC_UI.getServicesBaseInfo().find(service => service.id === recaptchaId).consent.status;
        const yumpuAllowed = UC_UI.getServicesBaseInfo().find(service => service.id === yumpuId).consent.status;
        const podigeeAllowed = UC_UI.getServicesBaseInfo().find(service => service.id === podigeeId).consent.status;
        const iframes = $('iframe');
        iframes.each(function() {
            checkForYouTubeIframe(this, youTubeAllowed);
            checkForGoogleMapsIframe(this, googleMapsAllowed);
            checkForYumpuIframe(this, yumpuAllowed);
            checkForPodigeeIframe(this, podigeeAllowed);
        });

        checkForRecaptcha(recaptchaAllowed);
    } else {
        console.log('waiting for usercentrics');
        setTimeout(initUserCentrics, 100);
    }
};

$(() => {
    initUserCentrics();
});

export const isTrboAllowed = () => {
    if (window['UC_UI'] !== undefined && UC_UI.isInitialized) {
        return UC_UI.getServicesBaseInfo().find(service => service.id === trboId).consent.status;
    } else {
        return false;
    }
};

window.addEventListener('onConsentChanged', function(e: any) {
    if (e.detail.event === 'consent_status' && e.detail['Google Maps']) {
        $('iframe').each(function() {
            showGoogleMapsIframe(this);
        });
    }
    if (e.detail.event === 'consent_status' && e.detail['YouTube Video']) {
        $('iframe').each(function() {
            showYoutubeIframe(this);
        });
    }
    if (e.detail.event === 'consent_status' && e.detail['yumpu']) {
        $('iframe').each(function() {
            showYumpuIframe(this);
        });
    }
    if (e.detail.event === 'consent_status' && e.detail['Podigee']) {
        $('iframe').each(function() {
            showPodigeeIframe(this);
        });
    }
    if (e.detail.event === 'consent_status' && e.detail['reCAPTCHA']) {
        showrecaptcha();
        if (e.detail.action === 'onUpdateServices') {
            showrecaptcha(true);
        }
    }
});
