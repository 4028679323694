import { availabilityReminderSubmitTracking } from "../Produktdetails/ProductGoogleTracking";
import { trackIdLayerReduced } from "../Tracking/GoogleTracking";
import { checkForRecaptcha, recaptchaId } from "./userCentrics";

declare var UC_UI: any;
declare var grecaptcha: any;
let currentProductId: string;
let currentTrackingEvent: string;
let modalForm: boolean;

$(() => {
    

    /**
     * Wird getriggert, wenn auf einen Button zum öffnen des Reminder Modals gedrückt wird
     */
    $(document).on('click', '.openSendReminderModal', function() {
        const productId = $(this).data('id');
        const trackingEvent = $(this).data('tracking');
        modalForm = true;
        currentTrackingEvent = trackingEvent;
        currentProductId = productId;
        const modal = $('#modalSendReminder');
        if (window['grecaptcha'] == undefined) {
            loadRecaptcha();
        }
        else {
            intiRecaptcha()
        }
        if (productId) {
            $(modal).modal();
        }
    });
});

/**
     * Triggers form submit for availabity reminder
     */
 $(".submitAvailabilityReminder").click(function() {
    let validations = {
        email: [
            /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
        ],
    };
    
    $('#Reminder_Email_Validation').addClass('hide');
    $('#Reminder_Email_Success').addClass('hide');
    $('#Reminder_Email_Server_Error').addClass('hide');
    $('#Reminder_Email_Validation_Modal').addClass('hide');
    $('#Reminder_Email_Success_Modal').addClass('hide');
    $('#Reminder_Email_Server_Error_Modal').addClass('hide');

    let recaptchaResponse;
    if (grecaptcha == undefined) {
        $(modalForm ? '#Reminder_Recaptcha_Validation_Modal' : '#Reminder_Recaptcha_Validation').removeClass('hide');
        return false;
    }
    try {
        let widgetId = $(modalForm ? "#reminder-recaptcha-modal" : "#reminder-recaptcha").data('widget');
        recaptchaResponse = grecaptcha.getResponse(widgetId);
        
    } catch {
        $(modalForm ? '#Reminder_Recaptcha_Validation_Modal' : '#Reminder_Recaptcha_Validation').removeClass('hide');
        return false;
    }
    if (recaptchaResponse.length == 0) {
        $(modalForm ? '#Reminder_Recaptcha_Validation_Modal' : '#Reminder_Recaptcha_Validation').removeClass('hide');
        return false;
    }

    let validation = new RegExp(validations['email'][0]);
    let email = modalForm ? <HTMLInputElement>$('#EmailReminderModal')[0] : <HTMLInputElement>$('#EmailReminder')[0];
    if (!validation.test(email.value)) {
        $('#Reminder_Email_Validation').removeClass('hide');
        return false;
    }

    const productId = $(this).data("id");
    let reminderEntry = {};
    reminderEntry['Email'] = email.value;
    reminderEntry["RecaptchaResponse"] = recaptchaResponse;
    reminderEntry["ProductId"] = productId || currentProductId;
    

    $.post(
        '/Umbraco/Api/AvailiablitySubscription/Subscribe',
        reminderEntry,
        function(data, status) {
            $(modalForm ? '#Reminder_Email_Success_Modal' : '#Reminder_Email_Success').removeClass('hide');
            availabilityReminderSubmitTracking(productId ? productId : currentProductId, currentTrackingEvent ? currentTrackingEvent : "erinnnerungsservice");
            
            if (data) {
                trackIdLayerReduced(data, "idLayer_reduced_reminder-service");    
            }
            
            currentTrackingEvent = null;
            currentProductId = null;
            modalForm = false;
            setTimeout(() => {
                $('#modalSendReminder').modal("hide");
                $('#EmailReminder').val("");
                $('#Reminder_Email_Success').addClass('hide');
                grecaptcha.reset();
            }, 2000);
        }
    ).fail(function(data) {
        $(modalForm ? '#Reminder_Email_Server_Error_Modal' : '#Reminder_Email_Server_Error').removeClass('hide');
    });
});

function loadRecaptcha() {
    var script = document.createElement('script');
    script.onload = function() {
        if (window["UC_UI"] !== undefined && UC_UI.isInitialized) {
            intiRecaptcha();
        }
    };
    script.src = 'https://www.google.com/recaptcha/api.js';

    document.head.appendChild(script);
}

function intiRecaptcha() {
    const recaptchaAllowed = UC_UI.getServicesBaseInfo().find(service => service.id === recaptchaId).consent.status;
    checkForRecaptcha(recaptchaAllowed, true);
}
