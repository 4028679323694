import request = require('request');
import { DummyImageSize } from '../Shared/Models/DummyImageSizeEnum';
import { IAvailabilityStatus } from './Models/AvailabilityStatus';
import { IDRMMapping } from './Models/DRMMapping';
import { StandardLink } from '../Produktdetails/Models/StandardLink';

/**
 * Holt den availabilityStatus zur übergebenen Id
 * @param availabilityStatusId
 */
export function getAvailabilityStatus(availabilityStatusId: number): Promise<string> {
    return new Promise((resolve, reject) => {
        var storedAvailabilityStatusList = JSON.parse(
            sessionStorage.getItem('availabilityStatusList')
        ) as IAvailabilityStatus[];
        if (storedAvailabilityStatusList) {
            resolve(storedAvailabilityStatusList.filter(x => x.id == availabilityStatusId)[0].text);
        } else {
            $.ajax({
                method: 'GET',
                dataType: 'JSON',
                url: '/Umbraco/api/Settings/GetAvailabilityStatusList',
                success: (availabilityStatusList: IAvailabilityStatus[]) => {
                    sessionStorage.setItem('availabilityStatusList', JSON.stringify(availabilityStatusList));
                    resolve(availabilityStatusList.filter(x => x.id == availabilityStatusId)[0].text);
                },
                error: () => {
                    reject();
                },
            });
        }
    });
}

/**
 * Holt die DRM-Info zur übergebenen Id
 * @param availabilityStatusId
 */
export function getDRMInfo(drmId: number): Promise<string> {
    return new Promise((resolve, reject) => {
        var drmMapping = JSON.parse(sessionStorage.getItem('drmMapping')) as IDRMMapping[];
        if (drmMapping) {
            let drmMappingItem = drmMapping.filter(x => x.DRmid == drmId)[0];
            if (drmMappingItem) {
                resolve(drmMappingItem.AnzeigeText);
            }
            resolve(null);
        } else {
            $.ajax({
                method: 'GET',
                dataType: 'JSON',
                url: '/Umbraco/api/Settings/GetDRMMapping',
                success: (drmMapping: IDRMMapping[]) => {
                    sessionStorage.setItem('drmMapping', JSON.stringify(drmMapping));
                    let drmMappingItem = drmMapping.filter(x => x.DRmid == drmId)[0];
                    if (drmMappingItem) {
                        resolve(drmMappingItem.AnzeigeText);
                    }
                    resolve(null);
                },
                error: () => {
                    reject();
                },
            });
        }
    });
}

/**
 * Holt den Link zu den Systemanforderungen (In Umbraco hinterlegt)
 * @param availabilityStatusId
 */
export function getSystemanforderungenLink(): Promise<StandardLink> {
    return new Promise((resolve, reject) => {
        var systemanforderungenLink = JSON.parse(sessionStorage.getItem('systemanforderungenLink')) as StandardLink;
        if (systemanforderungenLink) {
            resolve(systemanforderungenLink);
        } else {
            $.ajax({
                method: 'GET',
                dataType: 'JSON',
                url: '/Umbraco/api/Settings/GetSystemanforderungenLink',
                success: (systemanforderungenLink: StandardLink) => {
                    sessionStorage.setItem('systemanforderungenLink', JSON.stringify(systemanforderungenLink));
                    resolve(systemanforderungenLink);
                },
                error: () => {
                    reject();
                },
            });
        }
    });
}

/**
 * Checkt, ob die übergene availabilityStatusId lieferbar ist oder nicht
 * @param availabilityStatusId
 */
export function checkAvailabilityStatus(availabilityStatusId: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
        var storedOrderableAvailabilityStatusList = JSON.parse(
            sessionStorage.getItem('orderableAvailabilityStatusList')
        ) as number[];
        if (storedOrderableAvailabilityStatusList) {
            resolve(storedOrderableAvailabilityStatusList.some(x => x == availabilityStatusId));
        } else {
            $.ajax({
                method: 'GET',
                dataType: 'JSON',
                url: '/Umbraco/api/Settings/GetOrderableAvailabilityStatusList',
                success: (orderableAvailabilityStatusList: number[]) => {
                    sessionStorage.setItem(
                        'orderableAvailabilityStatusList',
                        JSON.stringify(orderableAvailabilityStatusList)
                    );
                    resolve(orderableAvailabilityStatusList.includes(availabilityStatusId));
                },
                error: () => {
                    reject();
                },
            });
        }
    });
}

/**
 * Holt die AdditionalPriceInfo zu dem Deliverytype. Daten sind in Backend Pflegbar
 * @param availabilityStatusId
 */
export function getAdditionalPriceInfoByDelivertypeId(deliverytypeId: number): Promise<string> {
    return new Promise((resolve, reject) => {
        let priceinfo = sessionStorage.getItem('priceinfo_' + deliverytypeId) as string;
        if (priceinfo) {
            resolve(priceinfo);
        } else {
            $.ajax({
                method: 'GET',
                url: '/Umbraco/api/Settings/GetAdditionalPriceInfoByDelivertypeId?deliverytypeId=' + deliverytypeId,
                success: (priceinfo: any) => {
                    sessionStorage.setItem('priceinfo_' + deliverytypeId, priceinfo);
                    resolve(priceinfo);
                },
                error: () => {
                    reject();
                },
            });
        }
    });
}

/**
 * Holt die AdditionalPriceInfo zu dem Deliverytype. Daten sind in Backend Pflegbar
 * @param availabilityStatusId
 */
export function getBreadcrumbByTocId(tocId: number): Promise<string> {
    return new Promise((resolve, reject) => {
        if (!tocId) {
            resolve('Produkt ohne Kategorie');
        }
        let breadcrumb = sessionStorage.getItem('breadcrumb_' + tocId) as string;
        if (breadcrumb) {
            resolve(breadcrumb);
        } else {
            $.ajax({
                method: 'GET',
                url: '/Umbraco/api/Settings/GetBreadcrumbByTocId?tocId=' + tocId,
                success: (breadcrumb: string) => {
                    sessionStorage.setItem('breadcrumb_' + tocId, breadcrumb);
                    resolve(breadcrumb);
                },
                error: () => {
                    reject();
                },
            });
        }
    });
}

/**
 * Baut den Link zu einem Produktbild zusammen.
 * @param productId
 * @param width
 * @param height
 */
export function getImagePath(productId: number, width: number = -1, height: number = -1): Promise<string> {
    return new Promise((resolve, reject) => {
        $.ajax({
            method: 'GET',
            dataType: 'JSON',
            url:
                '/Umbraco/api/ImageServerApi/GetImageUrl?productId=' +
                productId +
                '&width=' +
                width +
                '&height=' +
                height,
            success: data => {
                resolve(data);
            },
            error: () => {
                reject();
            },
        });
    });
}

/**
 * gibt den Pfad des Dummybildes passend zur übergebenen Größe zurück
 * @param imageSize
 */
export function getDummyImage(imageSize: DummyImageSize): Promise<string> {
    return new Promise((resolve, reject) => {
        $.ajax({
            method: 'GET',
            dataType: 'JSON',
            url: '/Umbraco/api/ImageServerApi/GetDummyImage?imageSize=' + imageSize,
            success: data => {
                resolve(data);
            },
            error: () => {
                reject();
            },
        });
    });
}

/**
 * gilt für Factfinder-Produkte
 * @param product
 */
export function showBezugsartOverlay(product): boolean {
    return (
        product.DeliveryTypeID == '5' &&
        product.Bruttopreis != null &&
        product.PreisvariantenMerkmal == 'false' &&
        product.Urheberzeile != null &&
        (product.Verlag == 'C.H.BECK' || product.Verlag == 'Vahlen')
    );
}
