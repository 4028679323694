import { ITrackingProduct } from './AddToCartTrackingModel';

export class EcondaPush implements IEcondaPush {
    constructor(products: ITrackingProduct[]) {
        this.emosUrl = '/scripts/Econda/emos3.js';
        this.ecSiteId = document.head.querySelector('[name~=econdaurl][content]').getAttribute('content');
        this.ecContent = '';
        this.ecProducts = mapEcondaPushProducts(products);
    }
    emosUrl: string;
    ecSiteId: string;
    ecContent: string;
    ecProducts: IEcondaPushProduct[];
}

export interface IEcondaPush {
    emosUrl: string;
    ecSiteId: string;
    ecContent: string;
    ecProducts: IEcondaPushProduct[];
}

class EcondaPushProduct implements IEcondaPushProduct {
    constructor(trackingProduct: ITrackingProduct) {
        this.type = 'c_add';
        this.pid = trackingProduct.id;
        this.sku = trackingProduct.sku;
        this.name = trackingProduct.comboName;
        this.price = trackingProduct.price;
        this.group = trackingProduct.category;
        this.count = trackingProduct.quantity;
        this.var1 = trackingProduct.variant;
        this.var2 = trackingProduct.displayFormat;
        this.var3 = trackingProduct.publisherName;
        this.var4 = trackingProduct.availabilityStatusId.toString();
        this.var5 = buildVar5(trackingProduct);
        this.var6 = trackingProduct.isApproximatePrice ? 'CaPreis' : 'Festpreis';
    }
    type: string;
    pid: number;
    sku: string;
    name: string;
    price: number;
    group: string;
    count: number;
    var1: string;
    var2: string;
    var3: string;
    var4: string;
    var5: string;
    var6: string;
    dimension8: string;
}

export interface IEcondaPushProduct {
    type: string;
    pid: number;
    sku: string;
    name: string;
    price: number;
    group: string;
    count: number;
    var1: string;
    var2: string;
    var3: string;
    var4: string;
    var5: string;
    var6: string;
    dimension8: string;
}

function mapEcondaPushProducts(trackingProducts: ITrackingProduct[]): IEcondaPushProduct[] {
    let econdaPushProducts = [] as IEcondaPushProduct[];
    trackingProducts.forEach((trackingProduct: ITrackingProduct) => {
        econdaPushProducts.push(new EcondaPushProduct(trackingProduct));
    });
    return econdaPushProducts;
}

function buildVar5(trackingProduct: ITrackingProduct): string {
    let firstPart = 'none';
    if (trackingProduct.isTop === true) {
        firstPart = 'isTop';
    } else if (trackingProduct.isStandardwerk === true) {
        firstPart = 'isStandardwerk';
    }

    var secondPart = 'none';
    if (trackingProduct.isNew == true) {
        secondPart = 'isNew';
    } else if (trackingProduct.isPreview == true) {
        secondPart = 'isPreview';
    }

    return `${firstPart}_${secondPart}`;
}
