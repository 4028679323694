import { Datum, ITrboResonse } from '../../Checkout/Models/Trbo/ITrboResonse';
import { ProduktkarussellItem, ProduktkarussellModel } from '../../Produktdetails/Models/Produktkarussell';

export const mapTrboResponseToProduktkarussellModel = (trboResponse: ITrboResonse): ProduktkarussellModel => {
    let response = {} as ProduktkarussellModel;
    response.karussellItems = [] as ProduktkarussellItem[];
    if (trboResponse.tracking.length > 0) {
        response.campaignId = trboResponse.tracking[0].campaign_id;
        response.campaignName = trboResponse.tracking[0].campaign_name;
        response.moduleId = trboResponse.tracking[0].module_id;
        response.moduleName = trboResponse.tracking[0].module_name;
        response.userType = trboResponse.tracking[0].user_type;
    }

    if (trboResponse.data) {
        for (let i = 0; i < trboResponse.data.length; i++) {
            for (let tr = 0; tr < trboResponse.data[i].length; tr++) {
                try {
                    const trboProduct = trboResponse.data[i][tr];
                    response.karussellItems.push({
                        authorstring: trboProduct.brand,
                        imageUrl: trboProduct.image_url,
                        isAnkuendigung: trboProduct.custom3 === 'True',
                        isEbook: false,
                        isNew: trboProduct.custom2 === 'True',
                        isStandardwerk: false,
                        isTopTitel: trboProduct.custom1 === 'True',
                        price: parseFloat(trboProduct.price).toLocaleString('de-De', {
                            style: 'currency',
                            currency: 'EUR',
                        }),
                        pricePrefix:
                            trboProduct.custom6 === 'True' ? 'ca. ' : trboProduct.custom7 === 'True' ? 'ab ' : '',
                        productDetailUrl: trboProduct.deeplink_orig,
                        productId: parseInt(trboProduct.article),
                        showBezugsartOverlay: showBezugsartOverlay(trboProduct),
                        title: trboProduct.label,
                        warenkorbAnzeigen: true,
                        isBeckOnline: trboProduct.custom10 === 'beck-online-Modul',
                        kupOrderNo: trboProduct.custom12,
                        dateOfPublicationDescription: '',
                        lieferstatus: trboProduct.custom14,
                        lieferstatusId: 0,
                        auflage: trboProduct.custom4,
                        releaseYear: trboProduct.custom5,
                        medium: trboProduct.custom10,
                        publisher: trboProduct.custom11,
                    });
                } catch (error) {
                    console.error(error);
                }
            }
        }
    }

    return response;
};
function showBezugsartOverlay(trboProduct: Datum): boolean {
    return (
        trboProduct.custom15 === '5' &&
        parseFloat(trboProduct.price) > 0 &&
        trboProduct.custom7 === 'False' &&
        (trboProduct.custom11 === 'C.H.BECK' || trboProduct.custom11 === 'Vahlen')
    );
}
