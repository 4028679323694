import request = require('request');
import { dangerAlert } from '../Shared/AlertHelper';
import IAddressChange = CheckoutflowRequest.IAddressChange;
import IPaymentMethodChange = CheckoutflowRequest.IPaymentMethodChange;
import IBillingAddress = CheckoutflowRequest.IBillingAddress;
import IShippingAddress = CheckoutflowRequest.IShippingAddress;
import ILineItemChange = CheckoutflowRequest.ILineItemChange;
import ICheckoutflowResponse = CheckoutflowReponse.ICheckoutflowResponse;
import IBskAdditionalInfo = CheckoutflowRequest.IBskAdditionalInfo;
import ICart = CheckoutflowReponse.ICart;
import CheckoutActionResponseModel = require('./Models/Api/CheckoutActionResponseModel');
import ICheckoutActionResponseModel = CheckoutActionResponseModel.ICheckoutActionResponseModel;
import { AddToCartTrackingLineItem } from './Tracking/checkoutTrackingGlobal';
let spinnerModal: JQuery = $('#spinnerModal');

export class CheckoutService {
    expressKauf(): Promise<boolean> {
        spinnerModal.removeClass('hide');
        return new Promise((resolve, reject) => {
            $.ajax({
                method: 'GET',
                url: '/Umbraco/api/ExpressKaufApi/Checkout',
                success: (response: boolean) => {
                    resolve(response);
                },
                error: data => {
                    //Message im Fehlerfall
                    reject(data.responseText);
                },
                complete: () => {
                    spinnerModal.addClass('hide');
                },
            });
        });
    }

    /**
     * Called den Api Controller um den aktuellen Warenkorb zu bekommen
     */
    showBasket(): Promise<ICheckoutflowResponse> {
        //Logs sind mit Absicht noch drin
        console.log('start-Request ' + new Date());
        spinnerModal.removeClass('hide');
        return new Promise((resolve, reject) => {
            $.ajax({
                method: 'GET',
                url: '/Umbraco/api/CheckoutApi/GetCart',
                success: (response: ICheckoutflowResponse) => {
                    resolve(response);
                },
                error: data => {
                    //Message im Fehlerfall
                    dangerAlert(data.responseText);
                    reject();
                },
                complete: () => {
                    spinnerModal.addClass('hide');
                    //Logs sind mit Absicht noch drin
                    console.log('End-Request ' + new Date());
                },
            });
        });
    }

    /**
     * Gibt die Zusätzlichen Informationen an die Cart weiter
     */
    addAdditionalInfoToCart(orderCode: string, customerMessage: string) {
        return new Promise((resolve, reject) => {
            $.ajax({
                method: 'POST',
                data: JSON.stringify({
                    OrderCode: orderCode,
                    CustomerMessage: customerMessage,
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
                type: 'json',
                url: '/Umbraco/api/CheckoutApi/AddAdditionalCartInfo',
                success: data => {
                    resolve(data);
                },
                error: data => {
                    dangerAlert(data.responseText);
                    reject();
                },
            });
        });
    }

    /**
     *
     * @param lineItemChange
     */
    addDiscountToCart(code: string): Promise<any> {
        return new Promise((resolve, reject) => {
            $.ajax({
                method: 'POST',
                //data: { "code": code },
                url: '/Umbraco/api/CheckoutApi/AddDiscountToCart?code=' + code,
                success: data => {
                    resolve(data);
                },
                error: data => {
                    dangerAlert(data.responseText);
                    reject();
                },
            });
        });
    }

    /**
     * Called den Api Controller um ein Produkt derm Warenkorb über seine ISBN hinzuzufügen
     * @param lineItemChange
     */
    addItemByIsbnSearch(isbn: string): Promise<ICart> {
        spinnerModal.removeClass('hide');
        return new Promise((resolve, reject) => {
            $.ajax({
                method: 'POST',
                data: JSON.stringify({ isbn: isbn }),
                headers: {
                    'Content-Type': 'application/json',
                },
                type: 'json',
                url: '/Umbraco/api/CheckoutApi/AddItemByIsbnSearch',
                success: (response: ICheckoutActionResponseModel) => {
                    AddToCartTrackingLineItem(response.lineItem);
                    resolve(response.cart);
                },
                error: data => {
                    dangerAlert(data.responseText);
                    reject();
                },
                complete: () => {
                    spinnerModal.addClass('hide');
                },
            });
        });
    }

    /**
     * Called den Api Controller um ein Produkt im Warenkorb zu updaten
     * @param lineItemChange
     */
    updateLineItem(lineItemChange: ILineItemChange): Promise<ICart> {
        return new Promise((resolve, reject) => {
            $.ajax({
                method: 'POST',
                data: JSON.stringify(lineItemChange),
                headers: {
                    'Content-Type': 'application/json',
                },
                type: 'json',
                url: '/Umbraco/api/CheckoutApi/UpdateLineItem',
                success: (cart: ICart) => {
                    resolve(cart);
                },
                error: data => {
                    dangerAlert(data.responseText);
                    reject();
                },
            });
        });
    }

    /**
     * Called den Api Controller um ein Produkt aus dem  Warenkorb zu löschen
     * @param lineItemChange
     */
    deleteLineItem(lineItemChange: ILineItemChange): Promise<ICart> {
        spinnerModal.removeClass('hide');
        return new Promise((resolve, reject) => {
            $.ajax({
                method: 'POST',
                data: JSON.stringify(lineItemChange),
                headers: {
                    'Content-Type': 'application/json',
                },
                url: '/Umbraco/api/CheckoutApi/DeleteLineItem',
                success: (cart: ICart) => {
                    resolve(cart);
                },
                error: data => {
                    dangerAlert(data.responseText);
                    reject();
                },
                complete: () => {
                    spinnerModal.addClass('hide');
                },
            });
        });
    }

    /**
     * Called den Api Controller um ein Produkt dem Warenkorb hinzuzufügen
     * @param productId
     * @param pricetypeId
     */
    addProductToBasket(
        productId: number,
        pricetypeId: number,
        ChosenDeliveryType?: number
    ): Promise<ICheckoutActionResponseModel> {
        return new Promise((resolve, reject) => {
            $.ajax({
                method: 'POST',
                data: {
                    productId: productId,
                    priceTypeId: pricetypeId,
                    chosenDeliveryType: ChosenDeliveryType,
                },
                url: '/Umbraco/api/CheckoutApi/AddItemToBasket',
                success: (response: ICheckoutActionResponseModel) => {
                    resolve(response);
                },
                error: data => {
                    dangerAlert(data.responseText);
                    reject();
                },
            });
        });
    }

    /**
     * Called den Api Controller um die Anzahl der Produke im Warenkorb zu erhalten
     */
    getItemCountInBasket(): Promise<any> {
        return new Promise((resolve, reject) => {
            $.ajax({
                method: 'GET',
                url: '/Umbraco/api/MiniBasket/GetMiniBasket',
                success: data => {
                    resolve(data);
                },
                error: data => {
                    reject();
                },
            });
        });
    }

    addPaymentShippingDataToCart(
        addresschange: IAddressChange,
        paymentmethodChange: IPaymentMethodChange,
        bskAdditionalInfo: IBskAdditionalInfo,
        relatedBusinessPartnerId: string
    ): Promise<void> {
        spinnerModal.removeClass('hide');

        var data = {
            addresschange: addresschange,
            paymentmethodchange: paymentmethodChange,
            relatedBusinessPartnerId: relatedBusinessPartnerId,
            bskAdditionalInfo: bskAdditionalInfo,
        };

        return new Promise((resolve, reject) => {
            $.ajax({
                method: 'POST',
                url: '/Umbraco/api/CheckoutApi/AddPaymentShippingDataToCart',
                data: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                },
                type: 'json',
                success: data => {
                    resolve(data);
                },
                error: data => {
                    dangerAlert(data.responseText.replace(/\"/g, ''));
                    reject();
                },
                complete: () => {
                    spinnerModal.addClass('hide');
                },
            });
        });
    }

    updateBillingaddressInCart(billingAddressId: string): Promise<string> {
        return new Promise((resolve, reject) => {
            $.ajax({
                method: 'POST',
                url: `/Umbraco/surface/CheckoutSurface/UpdateBillingAddress?addressId=${billingAddressId}`,
                success: data => {
                    resolve(data);
                },
                error: data => {
                    dangerAlert(data.responseText);
                    reject(data.responseText);
                },
                complete: () => {},
            });
        });
    }

    /**
     * Lädt die Lieferadressen die vom Benutzer ausgewählt werden können.
     * @param billingAddressId Die Id der BillingAddress
     */
    getAvailableDeliveryAddresses(billingAddressId: string, isBusiness: boolean): Promise<string> {
        return new Promise((resolve, reject) => {
            $.ajax({
                method: 'POST',
                url: `/Umbraco/surface/CheckoutSurface/GetAvailableDeliveryAddresses?addressId=${billingAddressId}&isBusiness=${isBusiness}`,
                success: data => {
                    resolve(data);
                },
                error: data => {
                    reject();
                },
                complete: () => {},
            });
        });
    }

    AddAddressesToCartGast(
        billingAddress: IBillingAddress,
        shippingAddress: IShippingAddress,
        emailAddress: string,
        shippingAsBilling: boolean,
        isGuestUser: boolean
    ): Promise<string> {
        return new Promise((resolve, reject) => {
            var data = {
                billingAddress: billingAddress,
                shippingAddress: shippingAddress,
                emailAddress: emailAddress,
                shippingAsBilling: shippingAsBilling,
                isGuestUser: isGuestUser,
            };

            $.ajax({
                method: 'POST',
                url: '/Umbraco/surface/CheckoutSurface/AddAddressesToCartGast',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(data),
                success: data => {
                    resolve(data);
                },
                error: data => {
                    if (data.responseText == 'Reload') {
                        window.location.reload();
                    } else {
                        dangerAlert(data.responseText);
                        reject();
                    }
                },
            });
        });
    }

    AddPaymentmethodToCartGast(
        paymentmethodchange: IPaymentMethodChange,
        isDataUsageAccepted: boolean,
        isGuestUser: boolean
    ): Promise<string> {
        return new Promise((resolve, reject) => {
            var data = {
                paymentmethodchange: paymentmethodchange,
                dataUsageAccepted: isDataUsageAccepted,
                isGuestUser: isGuestUser,
            };

            $.ajax({
                method: 'POST',
                url: '/Umbraco/surface/CheckoutSurface/AddPaymentmethodToCartGast',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(data),
                success: data => {
                    resolve(data);
                },
                error: data => {
                    dangerAlert(data.responseText);
                    reject();
                },
            });
        });
    }

    AddArvatoAddressesToCart(addresses) {
        return new Promise((resolve, reject) => {
            var data = {
                billingAddress: addresses.billingAddress,
                shippingAddress: addresses.shippingAddress,
            };

            $.ajax({
                method: 'POST',
                url: '/Umbraco/surface/CheckoutSurface/AddArvatoAddressesToCartGast',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(data),
                success: data => {
                    resolve(data);
                },
                error: data => {
                    if (data.responseText == 'Reload') {
                        window.location.reload();
                    } else {
                        dangerAlert(data.responseText);
                        reject();
                    }
                },
            });
        });
    }

    getSaferpayIframe(recaptchaResponse: string) {
        return new Promise((resolve, reject) => {
            $.ajax({
                method: 'POST',
                data: JSON.stringify({
                    value: recaptchaResponse,
                    url: window.location.href,
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
                type: 'json',
                url: '/Umbraco/api/CreditCardApi/GetsaferpayIframe',
                success: data => {
                    resolve(data);
                },
                error: data => {
                    dangerAlert(data.responseText);
                    reject();
                },
            });
        });
    }
}
