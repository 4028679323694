import { AddItemResponse } from "./addItemResponse";

export class ShoppingListService {
    
    /**
     * Enterfnert ein Produkt von der Shoppinglist
     * @param productId Die Id des Produkts was von der ShoppingList entfernt werden soll
     * @param completeFunction Wird ausgeführt, wenn der ajax request beendet wurde
     */
    deleteItem(productId: any, completeFunction: (xhr, status) => void) {
        $.ajax({
            method: "DELETE", 
            dataType: 'JSON',
            url: `/Umbraco/api/ShoppingList/DeleteItem?productId=${productId}`,
            complete: completeFunction
        });
    }


    /**
     * Fügt ein Produkt der Merkliste hinzu und aktualisiert bei Erfolg den Zähler
     * @param productId 
     */
    addItem(productId: any) {
        $.ajax({
            method: "POST", 
            dataType: 'JSON',
            url: `/Umbraco/api/ShoppingList/AddItem?productId=${productId}`,
            success: data => {
                var response = data as AddItemResponse;
                this.updateCounter($(".wrapper.remember.navItemSymbol"), response.count);
                this.showAlert(response.successMessage);
                $("#spinnerModal").addClass("hide");
            }
        });
    }

    /**
     * Aktualsiert das Symbol zur Anzahl der Items in der Merklsite
     */
    updateShoppingListCount() {
        var merklistenElement = $(".wrapper.remember.navItemSymbol");
        if (merklistenElement.length > 0) {
            $.ajax({
                method: "GET", 
                dataType: 'JSON',
                url: `/Umbraco/api/ShoppingList/GetShoppingListItemCount`,
                success: data => {
                    this.updateCounter(merklistenElement, data);
                }
            });    
        }
    }

    private updateCounter(element, count) {
        if (count > 0) {
            element.html(`<span class='notify'>${count}</span>`);
        }
        else {
            element.html("");
        }
    }

    private showAlert(message) {
        $("#merklisteAddId p").text(message)
        $("#merklisteAddId").show().delay(3000).fadeOut();
    }
}