import ProductHelper = require('../Shared/ProductHelper');
import { CheckoutService } from './checkoutService';
import { getItemCountForBasket } from './checkoutShared';
import { getFittingImage } from '../Shared/lazyImageLoader';
import 'bootstrap';
import IPrice = CheckoutflowReponse.IPrice;
import CheckoutActionResponseModel = require('./Models/Api/CheckoutActionResponseModel');
import ICheckoutActionResponseModel = CheckoutActionResponseModel.ICheckoutActionResponseModel;
import { buildTrboRequestUrl } from '../Shared/Trbo/TrboRequestBuilder';
import { isTrboAllowed } from '../Shared/userCentrics';
import { ProduktkarussellModel } from '../Produktdetails/Models/Produktkarussell';
import { mapTrboResponseToProduktkarussellModel } from '../Shared/Trbo/TrboResponseMapper';
import { trackGooglePromotionEvent_view } from '../Tracking/GoogleTracking';
const checkoutService = new CheckoutService();
const spinnerModal = $('#spinnerModal');
const similarProductsTrackingEvent = 'Product_Reco_cart-modal_s2s';
import * as Cookies from 'js-cookie';
import { AddToCartTrackingLineItem, TrackBasketOverlayInteraction } from './Tracking/checkoutTrackingGlobal';

$(() => {
    //Funktionen, die beim Seitenstart aufgerufen werden müssen
    getItemCountForBasket();

    $('body').on('click', '.basketLink', function() {
        if (window.innerWidth >= 992 && $('.headerBasket.notify').hasClass('hide') === false) {
            TrackBasketOverlayInteraction('meta-cart_view');
            $('.basket-menu-bubble').show();
            return false;
        }
    });

    $('.basket-menu-bubble').on('click', '.closeIcon', function() {
        $('.basket-menu-bubble').hide();
    });

    $('.basket-menu-bubble').on('click', '.basket-menu-bubble-tracking', function() {
        TrackBasketOverlayInteraction($(this).data('label'));
    });

    /**
     * Triggert onClick das hinzufügen eines Produktes zum Warenkorb und blendet in der Ladezeit einen Spinner ein
     */
    $('body').on('click', '.ajax_checkout_add', function() {
        let button = $(this);
        let noModal = button.hasClass('noModal');
        checkoutAdd(button, !noModal, null);
    });
    $('.ajax_checkout_add_mobile').on('click', function() {
        let button = $(this);
        button.addClass('hide');
        spinnerModal.removeClass('hide');
        let pricetypeId = $('#mobilePriceSelect')
            .find('option:selected')
            .data('pricetypeid');
        let showModal = !$(this).hasClass('noModal');
        addItemToBasket(button, pricetypeId, showModal);
    });

    $('body').on('click', '.ajax_checkout_bezugsart', function() {
        let elem = $(this);
        let productId = elem.data('productid');
        let pricetypeId = elem.data('pricetypeid');
        let showModal = elem.hasClass('showModal');
        let auswahlButton = $('#modalBezugsart-01 .bezugsart_auswahl');
        auswahlButton.data('productid', productId);
        auswahlButton.data('pricetypeid', pricetypeId);
        auswahlButton.addClass('showModal');
    });
    $('.bezugsart_auswahl').on('click', function() {
        let elem = $(this);
        var productId = elem.data('productid');
        var pricetypeId = elem.data('pricetypeid');
        var chosenDeliveryType = elem.data('chosendelivertype') as number;
        var showModal = elem.hasClass('showModal');
        spinnerModal.removeClass('hide');
        addItemToBasket(elem, pricetypeId, showModal, chosenDeliveryType);
    });

    $('#login-forbidden-order').on('click', function(event) {
        event.preventDefault();
        if ($('.headerBasket.notify').not('.hide').length > 0) {
            window.location.href = $(this).data('basketlink');
        } else {
            window.location.href = '/';
        }
    });
});

/**
 * Fügt ein Produkt dem Warenkorb hinzu. Während der Prozess läuft wird der Warenkorbbutton durch einen Spinner ersetzt
 * @param element
 */
export function checkoutAdd(button: JQuery, showModal: boolean, ChosenDeliveryType?: number) {
    button.addClass('hide');
    spinnerModal.removeClass('hide');
    let pricetypeId = +button.data('pricetypeid');
    addItemToBasket(button, pricetypeId, showModal, ChosenDeliveryType);
}

/**
 * Fügt ein Produkt dem Warenkorb hinzu
 * @param e
 */
function addItemToBasket(button: JQuery, pricetypeId: number, showModal: boolean, chosenDeliveryType?: number) {
    const productId = button.data('productid');
    checkoutService
        .addProductToBasket(productId, pricetypeId, chosenDeliveryType)
        .then(async (response: ICheckoutActionResponseModel) => {
            await getItemCountForBasket();
            AddToCartTrackingLineItem(response.lineItem);
            if (showModal) {
                fillModalWeiterShoppen(response);
            } else {
                window.location.href = '/warenkorb';
            }
        })
        .catch(() => {})
        .then(() => {
            spinnerModal.addClass('hide');
            button.removeClass('hide');
        });
}

/**
 * Füllt das WeiterShoppen-Modal mit den Daten des hinzugefügten Produkt
 * @param data
 */
async function fillModalWeiterShoppen(response: ICheckoutActionResponseModel) {
    try {
        let lineItem = response.lineItem;
        let product = lineItem.product;
        if (product != null) {
            let modal = $('#modalWeiterShoppen');

            if (product.prices != null) {
                let price = product.prices.filter(
                    x => x.priceType.priceTypeId == lineItem.currentPriceType.priceTypeId
                )[0] as IPrice;
                if (price != null) {
                    modal.find('.results-main .price').html(
                        (price.priceGross / 100).toLocaleString('de-De', {
                            style: 'currency',
                            currency: price.currency.currencyIsoCode,
                        })
                    );
                    if (price.isApproximatePrice) {
                        modal.find('.results-main .price').prepend('ca. ');
                    }
                    modal.find('.results-main .priceTypeDescription').html(price.priceType.priceTypeDescription);
                }
                modal
                    .find('.results-main .ProductImage')
                    .attr('src', getFittingImage(response.imageSizeOptions.SizeOptions));
                modal.find('.results-main .author').html(product.authorList);
                modal.find('.results-main .title').html(product.titleMain);
                if (product.isbnIssn) {
                    modal.find('.results-main .isbn-webcode').html('ISBN ' + product.isbnIssn);
                } else {
                    modal.find('.results-main .isbn-webcode').addClass('hide');
                }
                modal
                    .find('.results-main .availabilityStatus')
                    .html(await ProductHelper.getAvailabilityStatus(product.availabilityStatusId));
                if (product.availabilityStatusId == 1 || product.availabilityStatusId == 4) {
                    modal.find('.results-main .availabilityStatus').addClass('availabilityStatusColorGreen');
                    modal.find('.results-main .availability img').removeClass('hide');
                }
                modal.find('.validationMessage.success').html(response.message);
                spinnerModal.addClass('hide');
                $(modal).modal();

                fillModalWeiterShoppenRecomendation(product.productId);
            }
        }
    } catch (e) {
        spinnerModal.addClass('hide');
        $('.ajax_checkout_add').removeClass('hide');
    }
}

const fillModalWeiterShoppenRecomendation = (productId: number) => {
    hideRecommandation();
    if (isTrboAllowed()) {
        const url = buildTrboRequestUrl('Product_Reco_cart-modal_s2s', productId);
        $.ajax({
            method: 'GET',
            dataType: 'JSON',
            url: encodeURI(url),
            success: data => {
                try {
                    const trboProducts = mapTrboResponseToProduktkarussellModel(data) as ProduktkarussellModel;
                    if (trboProducts.karussellItems.length === 0) {
                        return;
                    }
                    const productContainer = $('.similar-products');
                    trboProducts.karussellItems.slice(0, 4).forEach(trboProduct => {
                        let productPrototype = $('#similar-products-proto').clone();
                        productPrototype
                            .find('.title a')
                            .html(trboProduct.title)
                            .attr('href', trboProduct.productDetailUrl)
                            .addClass('similarProductTracking')
                            .attr('data-trackingevent', similarProductsTrackingEvent);

                        productPrototype.find('.author').html(trboProduct.authorstring);

                        productPrototype.find('.imgWrapper img').attr('src', replaceImageHeight(trboProduct.imageUrl));
                        productPrototype
                            .find('.imgLink')
                            .attr('href', trboProduct.productDetailUrl)
                            .addClass('similarProductTracking')
                            .attr('data-trackingevent', similarProductsTrackingEvent);

                        productPrototype.find('.price').html(trboProduct.pricePrefix + trboProduct.price);
                        productPrototype.find('.year').html(trboProduct.releaseYear);
                        productPrototype.find('.edition').html(trboProduct.auflage);
                        productPrototype.find('.medium').html(trboProduct.medium);
                        productPrototype.find('.publisher').html(trboProduct.publisher);

                        if (trboProduct.warenkorbAnzeigen) {
                            productPrototype.find('.ajax_checkout_add').data('productid', trboProduct.productId);
                            productPrototype.find('.ajax_checkout_add').removeClass('hide');
                        }

                        productContainer.append(productPrototype);
                    });

                    trackGooglePromotionEvent_view(similarProductsTrackingEvent);
                    $('.similar-products').show();
                } catch {
                    hideRecommandation();
                }
            },
        });
    }
};

function replaceImageHeight(productDetailUrl: string) {
    if (!productDetailUrl) {
        return productDetailUrl;
    }
    return productDetailUrl.replace('height=180', 'width=105');
}

const hideRecommandation = () => {
    $('.similar-products .row').remove();
    $('.similar-products').hide();
};
