/**
 * List alle Verfügbarkeitsstatus deren Produkte angezeigt werden dürfen
 */
export const availabilityStatusFilter = [
  1,
  3,
  4,
  5,
  8,
  9,
  10,
  11,
  12,
  13,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  32,
  33,
  34
];
