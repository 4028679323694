import { MobileNavApiResponeModel } from './Models/mobileNaviApiResponse';

export class MobileNavBuilder {
    /**
     * Baut die mobile Navigation anhand der Api Response auf
     * @param response
     */
    buildMobileNavi(response: MobileNavApiResponeModel) {
        $('ul.mobMenu.mobileNavigation').empty();
        if (response.showBack) {
            let backLink = $('#mobNavProtoTypes')
                .find('.backLink')
                .clone();

            const breadcumb = $('#mobNavProtoTypes')
                .find('.mobile-menu-breadcrumb')
                .clone();

            if (!response.breadcrumb) {
                breadcumb.find('.mobile-menu-breadcrumb-root').remove();
                breadcumb.find('.mobile-menu-breadcrumb-dots').remove();
                breadcumb.find('.mobile-menu-breadcrumb-arrow').remove();
            } else {
                breadcumb.find('.mobile-menu-breadcrumb-root').html(response.breadcrumb.name);
                breadcumb.find('.mobile-menu-breadcrumb-root').attr('data-id', response.breadcrumb.id + '');
                breadcumb
                    .find('.mobile-menu-breadcrumb-root')
                    .attr('data-weitere', response.breadcrumb.isOtherTopics + '');

                if (response.breadcrumb.showDots === false) {
                    breadcumb.find('.mobile-menu-breadcrumb-dots').remove();
                    breadcumb
                        .find('.mobile-menu-breadcrumb-arrow')
                        .first()
                        .remove();
                }
            }

            breadcumb.find('.mobile-menu-breadcrumb-current').html(response.name);

            backLink.attr('data-id', response.backId + '');
            backLink.attr('data-weitere', response.parentWeitere + '');
            backLink.attr('data-fachbereich', response.parentFachbereichDetail + '');
            backLink.find('span.txt').html(response.backLinkTitle);

            $('ul.mobMenu.mobileNavigation').append(backLink);
            $('ul.mobMenu.mobileNavigation').append(breadcumb);
        }
        response.mobileNavItems.forEach(navItem => {
            var navItemProtoType = $('#mobNavProtoTypes')
                .find('.mobNavListItem')
                .clone();

            navItemProtoType.find('.txt').html(navItem.name);
            if (!navItem.hasChildren) {
                navItemProtoType.find('i').remove();
                navItemProtoType.find('a').attr('href', navItem.link);
            }

            if (navItem.id) {
                navItemProtoType.find('a').attr('data-id', navItem.id);
            }

            navItemProtoType.find('a').attr('data-fachbereich', navItem.fachbereichDetail + '');
            navItemProtoType.find('a').attr('data-weitere', navItem.weitereFachgebiete + '');
            if (navItem.weitereFachgebiete) {
                navItemProtoType.find('a').attr('data-id', '-1');
            }

            $('ul.mobMenu.mobileNavigation').append(navItemProtoType);
        });
    }
}
