/**
 * Formatiert den Übergebenen Betrag in das Format 0.00.
 * Wenn es sich um einen Cent-Betrag handelt wird vorher /100 geteilt
 * @param price
 * @param isCentPrice
 */
export function formatPrice2Digits(price: number, isCentPrice?: boolean) {
    try {
        if (!price && price != 0) {
            return null;
        }
        if (isCentPrice) {
            price = price / 100;
        }
        return price.toFixed(2);
    } catch (error) {
        return null;
    }
}